import { Alignment, AutoLink, Autosave, Bold, Essentials, Heading, Link, List, Paragraph, SpecialCharacters, Table, TableCaption, TableCellProperties, TableColumnResize, TableProperties, TableToolbar } from 'ckeditor5';

function SpecialCharactersEmoji(editor: any) {
    editor.plugins.get('SpecialCharacters').addItems(
        'Emoji',
        [
            { title: 'clubs', character: '♣️' },
            { title: 'diamonds', character: '♦️' },
            { title: 'heart', character: '♥️' },
            { title: 'spades', character: '♠️' },
            { title: 'smile', character: '😊' },
        ],
        { label: 'Emoticons', icon: '♦️' }
    );
}

export const editorConfig = {
    toolbar: {
        items: ['heading', '|', 'alignment', 'bold', 'link', '|', 'bulletedList', 'numberedList', 'insertTable', 'specialCharacters'],
        shouldNotGroupWhenFull: false,
    },
    plugins: [SpecialCharacters, SpecialCharactersEmoji, Table, TableCaption, TableCellProperties, TableColumnResize, TableProperties, TableToolbar, Alignment, AutoLink, Autosave, Bold, Essentials, Heading, Link, List, Paragraph],
    heading: {
        options: [
            {
                model: 'paragraph',
                title: 'Paragraph',
                class: 'ck-heading_paragraph',
            },
            {
                model: 'heading1',
                view: 'h1',
                title: 'Heading 1',
                class: 'ck-heading_heading1',
            },
            {
                model: 'heading2',
                view: 'h2',
                title: 'Heading 2',
                class: 'ck-heading_heading2',
            },
            {
                model: 'heading3',
                view: 'h3',
                title: 'Heading 3',
                class: 'ck-heading_heading3',
            },
            {
                model: 'heading4',
                view: 'h4',
                title: 'Heading 4',
                class: 'ck-heading_heading4',
            },
            {
                model: 'heading5',
                view: 'h5',
                title: 'Heading 5',
                class: 'ck-heading_heading5',
            },
            {
                model: 'heading6',
                view: 'h6',
                title: 'Heading 6',
                class: 'ck-heading_heading6',
            },
        ],
    },
    image: {
        toolbar: ['imageTextAlternative'],
    },
    initialData: '<h2>Dodaj treść lekcji</h2>\n<p>\n   Tutaj mozesz dodac treść lekcji</p>',
    link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        decorators: {
            toggleDownloadable: {
                mode: 'manual',
                label: 'Downloadable',
                attributes: {
                    download: 'file',
                },
            },
        },
    },
    placeholder: 'Dodaj treść lekcji',
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
    },
};

export const slugify = (s: string): string => {
    const charMap = {
        // Polish characters
        ą: 'a',
        ć: 'c',
        ę: 'e',
        ł: 'l',
        ń: 'n',
        ó: 'o',
        ś: 's',
        ż: 'z',
        ź: 'z',
        // Spanish characters
        á: 'a',
        é: 'e',
        í: 'i',
        ú: 'u',
        ñ: 'n',
        // German characters
        ä: 'a',
        ö: 'o',
        ü: 'u',
        ß: 'ss',
        // French characters
        à: 'a',
        â: 'a',
        ç: 'c',
        è: 'e',
        ê: 'e',
        ë: 'e',
        î: 'i',
        ï: 'i',
        ô: 'o',
        û: 'u',
        ù: 'u',
        // Hungarian characters
        ő: 'o',
        ű: 'u',
    };

    return s
        .toLowerCase()
        .trim()
        .replace(/[ąćęłńóśżźáéíóúñäöüßàâçéèêëîïôûùü]/g, (match) => charMap[match])
        .replace(/ /g, '-')
        .replace(/[A-Z]/g, '');
};
