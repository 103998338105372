import * as Types from '../../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateLessonMutationVariables = Types.Exact<{
  input: Types.LessonCreateInput;
}>;


export type CreateLessonMutation = { __typename?: 'Mutation', lessonCreate: { __typename?: 'LessonCreateFailureView', reason: Types.LessonCreateFailureReasonView } | { __typename?: 'LessonCreateSuccessView', lessonSlug: string } };

export type EditLessonMutationVariables = Types.Exact<{
  input: Types.LessonRevisionCreateInput;
}>;


export type EditLessonMutation = { __typename?: 'Mutation', lessonRevisionCreate: { __typename?: 'LessonRevisionCreateFailureView', reason: Types.LessonRevisionCreateFailureReasonView } | { __typename?: 'LessonRevisionCreateSuccessView', lessonSlug: string } };

export type GetLessonsFromPublicQueryVariables = Types.Exact<{
  input: Types.LessonsSelectionInput;
}>;


export type GetLessonsFromPublicQuery = { __typename?: 'Query', publicGroups: Array<{ __typename?: 'GroupView', lessons: { __typename?: 'LessonViewPaginationResultSetView', items: Array<{ __typename?: 'LessonView', slug: string, title: string }> } }> };

export type GetLessonsFromGroupQueryVariables = Types.Exact<{
  lessonsInput2: Types.LessonsSelectionInput;
  code: Types.Scalars['String']['input'];
}>;


export type GetLessonsFromGroupQuery = { __typename?: 'Query', group?: { __typename?: 'GroupView', me?: { __typename?: 'GroupMemberView', role: Types.GroupMemberRoleView } | null, lessons: { __typename?: 'LessonViewPaginationResultSetView', items: Array<{ __typename?: 'LessonView', isHomeworkDone: boolean, title: string, slug: string, hasHomework: boolean }> } } | null };

export type LessonOrderChangeMutationVariables = Types.Exact<{
  input: Types.LessonOrderChangeInput;
}>;


export type LessonOrderChangeMutation = { __typename?: 'Mutation', lessonOrderChange: { __typename?: 'LessonOrderChangeFailureView', reason: Types.LessonOrderChangeFailureReasonView } | { __typename?: 'LessonOrderChangeSuccessView', lessonSlug: string } };

export type LessonDeleteMutationVariables = Types.Exact<{
  input: Types.LessonDeleteInput;
}>;


export type LessonDeleteMutation = { __typename?: 'Mutation', lessonDelete: { __typename?: 'LessonDeleteFailureView', reason: Types.LessonDeleteFailureReasonView } | { __typename?: 'LessonDeleteSuccessView', deletedAt: any } };

export type GetLessonBySlugQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
  lessonInput2: Types.LessonSelectionInput;
}>;


export type GetLessonBySlugQuery = { __typename?: 'Query', group?: { __typename?: 'GroupView', me?: { __typename?: 'GroupMemberView', role: Types.GroupMemberRoleView } | null, lesson: { __typename?: 'LessonView', content: string, createdAt: any, description: string, difficultyLevel: Types.LessonDifficultyLevelView, hasHomework: boolean, slug: string, tags: Array<string>, title: string, updatedAt: any, createdBy: { __typename?: 'UserPublicModelView', id: string, username: string }, modifiedBy?: Array<{ __typename?: 'UserPublicModelView', id: string, username: string }> | null } } | null };

export type GetLessonsHomeworkBySlugQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
  lessonInput2: Types.LessonSelectionInput;
}>;


export type GetLessonsHomeworkBySlugQuery = { __typename?: 'Query', group?: { __typename?: 'GroupView', me?: { __typename?: 'GroupMemberView', role: Types.GroupMemberRoleView } | null, lesson: { __typename?: 'LessonView', homework: Array<{ __typename?: 'ExamView', comment?: string | null, createdAt: any, myHighestResult?: Types.HighestExamResultView | null, difficultyLevel: Types.ExamDifficultyLevelView, id: string, name: string, revisionId: string, tags: Array<string>, vulnerability: Types.ExamVulnerabilityView, updatedAt: any, createdBy: { __typename?: 'UserPublicModelView', id: string, username: string }, definition: { __typename?: 'ExamDefinitionView', initialBiddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', value: Types.BiddingCardValueView, color: Types.BiddingCardColorView } | null }>, cards: Array<{ __typename?: 'CardView', color: Types.CardColorView, value: Types.CardValueView }>, answers: Array<{ __typename?: 'ExamAnswerView', biddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', value: Types.BiddingCardValueView, color: Types.BiddingCardColorView } | null }>, halfAnswers: Array<{ __typename?: 'BiddingView', shouldAlert: boolean, declaration: Types.BiddingDeclarationView, bidCard?: { __typename?: 'BiddingCardView', value: Types.BiddingCardValueView, color: Types.BiddingCardColorView } | null }>, matchingAnswer: { __typename?: 'BiddingView', shouldAlert: boolean, declaration: Types.BiddingDeclarationView, bidCard?: { __typename?: 'BiddingCardView', value: Types.BiddingCardValueView, color: Types.BiddingCardColorView } | null } }> }, modifiedBy: Array<{ __typename?: 'UserPublicModelView', username: string, id: string }> }> } } | null };


export const CreateLessonDocument = gql`
    mutation createLesson($input: LessonCreateInput!) {
  lessonCreate(input: $input) {
    ... on LessonCreateFailureView {
      reason
    }
    ... on LessonCreateSuccessView {
      lessonSlug
    }
  }
}
    `;
export type CreateLessonMutationFn = Apollo.MutationFunction<CreateLessonMutation, CreateLessonMutationVariables>;

/**
 * __useCreateLessonMutation__
 *
 * To run a mutation, you first call `useCreateLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLessonMutation, { data, loading, error }] = useCreateLessonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLessonMutation(baseOptions?: Apollo.MutationHookOptions<CreateLessonMutation, CreateLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLessonMutation, CreateLessonMutationVariables>(CreateLessonDocument, options);
      }
export type CreateLessonMutationHookResult = ReturnType<typeof useCreateLessonMutation>;
export type CreateLessonMutationResult = Apollo.MutationResult<CreateLessonMutation>;
export type CreateLessonMutationOptions = Apollo.BaseMutationOptions<CreateLessonMutation, CreateLessonMutationVariables>;
export const EditLessonDocument = gql`
    mutation editLesson($input: LessonRevisionCreateInput!) {
  lessonRevisionCreate(input: $input) {
    ... on LessonRevisionCreateFailureView {
      reason
    }
    ... on LessonRevisionCreateSuccessView {
      lessonSlug
    }
  }
}
    `;
export type EditLessonMutationFn = Apollo.MutationFunction<EditLessonMutation, EditLessonMutationVariables>;

/**
 * __useEditLessonMutation__
 *
 * To run a mutation, you first call `useEditLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLessonMutation, { data, loading, error }] = useEditLessonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditLessonMutation(baseOptions?: Apollo.MutationHookOptions<EditLessonMutation, EditLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditLessonMutation, EditLessonMutationVariables>(EditLessonDocument, options);
      }
export type EditLessonMutationHookResult = ReturnType<typeof useEditLessonMutation>;
export type EditLessonMutationResult = Apollo.MutationResult<EditLessonMutation>;
export type EditLessonMutationOptions = Apollo.BaseMutationOptions<EditLessonMutation, EditLessonMutationVariables>;
export const GetLessonsFromPublicDocument = gql`
    query getLessonsFromPublic($input: LessonsSelectionInput!) {
  publicGroups {
    lessons(input: $input) {
      items {
        slug
        title
      }
    }
  }
}
    `;

/**
 * __useGetLessonsFromPublicQuery__
 *
 * To run a query within a React component, call `useGetLessonsFromPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonsFromPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonsFromPublicQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLessonsFromPublicQuery(baseOptions: Apollo.QueryHookOptions<GetLessonsFromPublicQuery, GetLessonsFromPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLessonsFromPublicQuery, GetLessonsFromPublicQueryVariables>(GetLessonsFromPublicDocument, options);
      }
export function useGetLessonsFromPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLessonsFromPublicQuery, GetLessonsFromPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLessonsFromPublicQuery, GetLessonsFromPublicQueryVariables>(GetLessonsFromPublicDocument, options);
        }
export function useGetLessonsFromPublicSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLessonsFromPublicQuery, GetLessonsFromPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLessonsFromPublicQuery, GetLessonsFromPublicQueryVariables>(GetLessonsFromPublicDocument, options);
        }
export type GetLessonsFromPublicQueryHookResult = ReturnType<typeof useGetLessonsFromPublicQuery>;
export type GetLessonsFromPublicLazyQueryHookResult = ReturnType<typeof useGetLessonsFromPublicLazyQuery>;
export type GetLessonsFromPublicSuspenseQueryHookResult = ReturnType<typeof useGetLessonsFromPublicSuspenseQuery>;
export type GetLessonsFromPublicQueryResult = Apollo.QueryResult<GetLessonsFromPublicQuery, GetLessonsFromPublicQueryVariables>;
export const GetLessonsFromGroupDocument = gql`
    query getLessonsFromGroup($lessonsInput2: LessonsSelectionInput!, $code: String!) {
  group(code: $code) {
    me {
      role
    }
    lessons(input: $lessonsInput2) {
      items {
        isHomeworkDone
        title
        slug
        hasHomework
      }
    }
  }
}
    `;

/**
 * __useGetLessonsFromGroupQuery__
 *
 * To run a query within a React component, call `useGetLessonsFromGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonsFromGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonsFromGroupQuery({
 *   variables: {
 *      lessonsInput2: // value for 'lessonsInput2'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetLessonsFromGroupQuery(baseOptions: Apollo.QueryHookOptions<GetLessonsFromGroupQuery, GetLessonsFromGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLessonsFromGroupQuery, GetLessonsFromGroupQueryVariables>(GetLessonsFromGroupDocument, options);
      }
export function useGetLessonsFromGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLessonsFromGroupQuery, GetLessonsFromGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLessonsFromGroupQuery, GetLessonsFromGroupQueryVariables>(GetLessonsFromGroupDocument, options);
        }
export function useGetLessonsFromGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLessonsFromGroupQuery, GetLessonsFromGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLessonsFromGroupQuery, GetLessonsFromGroupQueryVariables>(GetLessonsFromGroupDocument, options);
        }
export type GetLessonsFromGroupQueryHookResult = ReturnType<typeof useGetLessonsFromGroupQuery>;
export type GetLessonsFromGroupLazyQueryHookResult = ReturnType<typeof useGetLessonsFromGroupLazyQuery>;
export type GetLessonsFromGroupSuspenseQueryHookResult = ReturnType<typeof useGetLessonsFromGroupSuspenseQuery>;
export type GetLessonsFromGroupQueryResult = Apollo.QueryResult<GetLessonsFromGroupQuery, GetLessonsFromGroupQueryVariables>;
export const LessonOrderChangeDocument = gql`
    mutation lessonOrderChange($input: LessonOrderChangeInput!) {
  lessonOrderChange(input: $input) {
    ... on LessonOrderChangeFailureView {
      reason
    }
    ... on LessonOrderChangeSuccessView {
      lessonSlug
    }
  }
}
    `;
export type LessonOrderChangeMutationFn = Apollo.MutationFunction<LessonOrderChangeMutation, LessonOrderChangeMutationVariables>;

/**
 * __useLessonOrderChangeMutation__
 *
 * To run a mutation, you first call `useLessonOrderChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonOrderChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonOrderChangeMutation, { data, loading, error }] = useLessonOrderChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLessonOrderChangeMutation(baseOptions?: Apollo.MutationHookOptions<LessonOrderChangeMutation, LessonOrderChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LessonOrderChangeMutation, LessonOrderChangeMutationVariables>(LessonOrderChangeDocument, options);
      }
export type LessonOrderChangeMutationHookResult = ReturnType<typeof useLessonOrderChangeMutation>;
export type LessonOrderChangeMutationResult = Apollo.MutationResult<LessonOrderChangeMutation>;
export type LessonOrderChangeMutationOptions = Apollo.BaseMutationOptions<LessonOrderChangeMutation, LessonOrderChangeMutationVariables>;
export const LessonDeleteDocument = gql`
    mutation lessonDelete($input: LessonDeleteInput!) {
  lessonDelete(input: $input) {
    ... on LessonDeleteFailureView {
      reason
    }
    ... on LessonDeleteSuccessView {
      deletedAt
    }
  }
}
    `;
export type LessonDeleteMutationFn = Apollo.MutationFunction<LessonDeleteMutation, LessonDeleteMutationVariables>;

/**
 * __useLessonDeleteMutation__
 *
 * To run a mutation, you first call `useLessonDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonDeleteMutation, { data, loading, error }] = useLessonDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLessonDeleteMutation(baseOptions?: Apollo.MutationHookOptions<LessonDeleteMutation, LessonDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LessonDeleteMutation, LessonDeleteMutationVariables>(LessonDeleteDocument, options);
      }
export type LessonDeleteMutationHookResult = ReturnType<typeof useLessonDeleteMutation>;
export type LessonDeleteMutationResult = Apollo.MutationResult<LessonDeleteMutation>;
export type LessonDeleteMutationOptions = Apollo.BaseMutationOptions<LessonDeleteMutation, LessonDeleteMutationVariables>;
export const GetLessonBySlugDocument = gql`
    query getLessonBySlug($code: String!, $lessonInput2: LessonSelectionInput!) {
  group(code: $code) {
    me {
      role
    }
    lesson(input: $lessonInput2) {
      content
      createdAt
      createdBy {
        id
        username
      }
      description
      difficultyLevel
      hasHomework
      modifiedBy {
        id
        username
      }
      slug
      tags
      title
      updatedAt
    }
  }
}
    `;

/**
 * __useGetLessonBySlugQuery__
 *
 * To run a query within a React component, call `useGetLessonBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonBySlugQuery({
 *   variables: {
 *      code: // value for 'code'
 *      lessonInput2: // value for 'lessonInput2'
 *   },
 * });
 */
export function useGetLessonBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetLessonBySlugQuery, GetLessonBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLessonBySlugQuery, GetLessonBySlugQueryVariables>(GetLessonBySlugDocument, options);
      }
export function useGetLessonBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLessonBySlugQuery, GetLessonBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLessonBySlugQuery, GetLessonBySlugQueryVariables>(GetLessonBySlugDocument, options);
        }
export function useGetLessonBySlugSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLessonBySlugQuery, GetLessonBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLessonBySlugQuery, GetLessonBySlugQueryVariables>(GetLessonBySlugDocument, options);
        }
export type GetLessonBySlugQueryHookResult = ReturnType<typeof useGetLessonBySlugQuery>;
export type GetLessonBySlugLazyQueryHookResult = ReturnType<typeof useGetLessonBySlugLazyQuery>;
export type GetLessonBySlugSuspenseQueryHookResult = ReturnType<typeof useGetLessonBySlugSuspenseQuery>;
export type GetLessonBySlugQueryResult = Apollo.QueryResult<GetLessonBySlugQuery, GetLessonBySlugQueryVariables>;
export const GetLessonsHomeworkBySlugDocument = gql`
    query getLessonsHomeworkBySlug($code: String!, $lessonInput2: LessonSelectionInput!) {
  group(code: $code) {
    me {
      role
    }
    lesson(input: $lessonInput2) {
      homework {
        comment
        createdAt
        createdBy {
          id
          username
        }
        myHighestResult
        definition {
          initialBiddingSequence {
            declaration
            bidCard {
              value
              color
            }
            shouldAlert
          }
          cards {
            color
            value
          }
          answers {
            biddingSequence {
              declaration
              shouldAlert
              bidCard {
                value
                color
              }
            }
            halfAnswers {
              shouldAlert
              declaration
              bidCard {
                value
                color
              }
            }
            matchingAnswer {
              shouldAlert
              declaration
              bidCard {
                value
                color
              }
            }
          }
        }
        difficultyLevel
        id
        modifiedBy {
          username
          id
        }
        name
        revisionId
        tags
        vulnerability
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetLessonsHomeworkBySlugQuery__
 *
 * To run a query within a React component, call `useGetLessonsHomeworkBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonsHomeworkBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonsHomeworkBySlugQuery({
 *   variables: {
 *      code: // value for 'code'
 *      lessonInput2: // value for 'lessonInput2'
 *   },
 * });
 */
export function useGetLessonsHomeworkBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetLessonsHomeworkBySlugQuery, GetLessonsHomeworkBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLessonsHomeworkBySlugQuery, GetLessonsHomeworkBySlugQueryVariables>(GetLessonsHomeworkBySlugDocument, options);
      }
export function useGetLessonsHomeworkBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLessonsHomeworkBySlugQuery, GetLessonsHomeworkBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLessonsHomeworkBySlugQuery, GetLessonsHomeworkBySlugQueryVariables>(GetLessonsHomeworkBySlugDocument, options);
        }
export function useGetLessonsHomeworkBySlugSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLessonsHomeworkBySlugQuery, GetLessonsHomeworkBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLessonsHomeworkBySlugQuery, GetLessonsHomeworkBySlugQueryVariables>(GetLessonsHomeworkBySlugDocument, options);
        }
export type GetLessonsHomeworkBySlugQueryHookResult = ReturnType<typeof useGetLessonsHomeworkBySlugQuery>;
export type GetLessonsHomeworkBySlugLazyQueryHookResult = ReturnType<typeof useGetLessonsHomeworkBySlugLazyQuery>;
export type GetLessonsHomeworkBySlugSuspenseQueryHookResult = ReturnType<typeof useGetLessonsHomeworkBySlugSuspenseQuery>;
export type GetLessonsHomeworkBySlugQueryResult = Apollo.QueryResult<GetLessonsHomeworkBySlugQuery, GetLessonsHomeworkBySlugQueryVariables>;