import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/store';

function isJWTValid(token) {
    const currentTime = Date.now() / 1000;

    try {
        const decoded = jwtDecode(token);
        return decoded.exp > currentTime;
    } catch (error) {
        return false;
    }
}

interface UseValidateTokenOptions {
    optional?: boolean;
}

export const useValidateToken = (options?: UseValidateTokenOptions) => {
    const { token } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        const unregisteredUser = JSON.parse(sessionStorage.getItem('unregisteredUser'));
        if (options?.optional && unregisteredUser) {
            return;
        }

        const isValid = isJWTValid(token);
        if (!isValid) {
            navigate('/login');
        }
        console.log('isJWTValid', isValid);
    }, []);
};
