import React from 'react';

export const Save = () => {
    return (
        <svg width="40" height="46" viewBox="0 0 40 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.71429 2.85715C2.5625 2.85715 0 5.41965 0 8.57143V37.1429C0 40.2946 2.5625 42.8571 5.71429 42.8571H34.2857C37.4375 42.8571 40 40.2946 40 37.1429V15.4732C40 13.9554 39.4018 12.5 38.3304 11.4286L31.4286 4.52679C30.3571 3.45536 28.9018 2.85715 27.3839 2.85715H5.71429ZM5.71429 11.4286C5.71429 9.84822 6.99107 8.57143 8.57143 8.57143H25.7143C27.2946 8.57143 28.5714 9.84822 28.5714 11.4286V17.1429C28.5714 18.7232 27.2946 20 25.7143 20H8.57143C6.99107 20 5.71429 18.7232 5.71429 17.1429V11.4286ZM20 25.7143C21.5155 25.7143 22.969 26.3163 24.0406 27.388C25.1122 28.4596 25.7143 29.9131 25.7143 31.4286C25.7143 32.9441 25.1122 34.3975 24.0406 35.4692C22.969 36.5408 21.5155 37.1429 20 37.1429C18.4845 37.1429 17.031 36.5408 15.9594 35.4692C14.8878 34.3975 14.2857 32.9441 14.2857 31.4286C14.2857 29.9131 14.8878 28.4596 15.9594 27.388C17.031 26.3163 18.4845 25.7143 20 25.7143Z"
                fill="#1B6614"
            />
        </svg>
    );
};
