import React from "react";
import { SmallCard } from "./SmallCard.tsx";

export const SmallCards = (props) => {
  const { cards, small } = props;

  return (
    <div className={`small-cards-wrapper`}>
      <div className={`small-cards`}>
        {cards &&
          cards.map((card, index) => (
            <SmallCard card={card} index={index} key={index} />
          ))}
      </div>
    </div>
  );
};
