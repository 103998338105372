import React from 'react';

export const Help = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.9163 30C20.4997 30 20.993 29.7983 21.3963 29.395C21.7997 28.9917 22.0008 28.4989 21.9997 27.9167C21.9986 27.3344 21.7975 26.8411 21.3963 26.4367C20.9952 26.0322 20.5019 25.8311 19.9163 25.8333C19.3308 25.8355 18.838 26.0372 18.438 26.4383C18.038 26.8394 17.8363 27.3322 17.833 27.9167C17.8297 28.5011 18.0313 28.9944 18.438 29.3967C18.8447 29.7989 19.3375 30 19.9163 30ZM18.4163 23.5833H21.4997C21.4997 22.6667 21.6041 21.9444 21.813 21.4167C22.0219 20.8889 22.6119 20.1667 23.583 19.25C24.3052 18.5278 24.8747 17.84 25.2913 17.1867C25.708 16.5333 25.9163 15.7489 25.9163 14.8333C25.9163 13.2778 25.3469 12.0833 24.208 11.25C23.0691 10.4167 21.7219 9.99999 20.1663 9.99999C18.583 9.99999 17.2986 10.4167 16.313 11.25C15.3275 12.0833 14.6397 13.0833 14.2497 14.25L16.9997 15.3333C17.1386 14.8333 17.4513 14.2917 17.938 13.7083C18.4247 13.125 19.1675 12.8333 20.1663 12.8333C21.0552 12.8333 21.7219 13.0767 22.1663 13.5633C22.6108 14.05 22.833 14.5844 22.833 15.1667C22.833 15.7222 22.6663 16.2433 22.333 16.73C21.9997 17.2167 21.583 17.6678 21.083 18.0833C19.8608 19.1667 19.1108 19.9861 18.833 20.5417C18.5552 21.0972 18.4163 22.1111 18.4163 23.5833ZM19.9997 36.6667C17.6941 36.6667 15.5275 36.2294 13.4997 35.355C11.4719 34.4805 9.70801 33.2928 8.20801 31.7917C6.70801 30.2905 5.52079 28.5267 4.64634 26.5C3.7719 24.4733 3.33412 22.3067 3.33301 20C3.3319 17.6933 3.76968 15.5267 4.64634 13.5C5.52301 11.4733 6.71023 9.70943 8.20801 8.20832C9.70579 6.70721 11.4697 5.51999 13.4997 4.64665C15.5297 3.77332 17.6963 3.33554 19.9997 3.33332C22.303 3.3311 24.4697 3.76888 26.4997 4.64665C28.5297 5.52443 30.2936 6.71165 31.7913 8.20832C33.2891 9.70499 34.4769 11.4689 35.3547 13.5C36.2325 15.5311 36.6697 17.6978 36.6663 20C36.663 22.3022 36.2252 24.4689 35.353 26.5C34.4808 28.5311 33.2936 30.295 31.7913 31.7917C30.2891 33.2883 28.5252 34.4761 26.4997 35.355C24.4741 36.2339 22.3075 36.6711 19.9997 36.6667Z"
                fill="#C48208"
            />
        </svg>
    );
};
