import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button/Button.tsx';
import { Header } from '../../../components/Header/Header.tsx';
import { setChosenGroup } from '../../../redux/userSlice.ts';
import '../FormPage.scss';
import { useSignUpMutation } from './signUpPage.gen.ts';
import { signupSchema } from './signUpSchema.ts';

const SignUpPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [signUpMutation, { data, loading, error }] = useSignUpMutation();

    useEffect(() => {
        if (data?.signUp.__typename === 'SignUpSuccessView') {
            navigate('/check-email');
        }
    }, [data]);

    const handleUnregistered = () => {
        sessionStorage.setItem('unregisteredUser', JSON.stringify(true));
        dispatch(setChosenGroup('general'));
        navigate('/game');
    };
    return (
        <div className="formPage formPage--signup">
            <Header formHeader={true} />
            <p className="formPage__heading">
                <span className="formPage__heading--licytapka">Licytapka</span> to Twoja partnerka w nauce licytacji brydżowych
            </p>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    username: '',
                }}
                validationSchema={signupSchema}
                onSubmit={(values) => {
                    signUpMutation({
                        variables: {
                            input: values,
                        },
                    });
                }}
            >
                {(formik) => {
                    const { errors, touched, isValid, dirty } = formik;

                    return (
                        <div className="formPage__formContainer">
                            <div className="formPage__col formPage__col--right">
                                <h2 className="formPage__title">Masz już konto?</h2>
                                <Button customClass="formPage__button" variant="ghost-white" onClick={() => navigate('/login')} text="Zaloguj się" />
                                <p className="formPage__noAccount">
                                    Nie chcesz korzystać z fajnych funkcjonalności jak Statystyki i Grupy?{' '}
                                    <Button
                                        customClass="formPage__link"
                                        variant="text"
                                        onClick={() => {
                                            handleUnregistered();
                                        }}
                                    >
                                        Korzystaj z Licytapki bez zakładania konta.
                                    </Button>
                                </p>
                            </div>
                            <div className="formPage__col formPage__col--left">
                                <h2 className="formPage__title">Załóż konto</h2>
                                <Form>
                                    <div className="formPage__formGroup form-row">
                                        <Field type="username" placeholder="Nick*" name="username" id="username" className={`formPage__formControl ${errors.username && touched.username ? 'input-error' : ''}`} />
                                        <ErrorMessage name="username" component="span" className="error" />
                                    </div>
                                    <div className="formPage__formGroup form-row">
                                        <Field type="email" placeholder="Email*" name="email" id="email" className={`formPage__formControl ${errors.email && touched.email ? 'input-error' : ''}`} />
                                        <ErrorMessage name="email" component="span" className="error" />
                                    </div>
                                    <div className="formPage__formGroup form-row">
                                        <Field placeholder="Hasło*" type="password" name="password" id="password" className={`formPage__formControl ${errors.password && touched.password ? 'input-error' : ''}`} />
                                        <ErrorMessage name="password" component="span" className="error" />
                                    </div>
                                    <button type="submit" className={`button formPage__button${!(dirty && isValid) ? ' disabled' : ''}`} disabled={!(dirty && isValid)}>
                                        Załóż konto
                                    </button>
                                </Form>

                                {error && <p className="formPage__error">{error.message}</p>}
                            </div>
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
};
export default SignUpPage;
