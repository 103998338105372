import React from 'react';

const Bin = () => {
    return (
        <svg width="40" height="46" viewBox="0 0 40 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_275_396)">
                <path d="M12.0714 1.58036L11.4286 2.85714H2.85714C1.27679 2.85714 0 4.13393 0 5.71429C0 7.29464 1.27679 8.57143 2.85714 8.57143H37.1429C38.7232 8.57143 40 7.29464 40 5.71429C40 4.13393 38.7232 2.85714 37.1429 2.85714H28.5714L27.9286 1.58036C27.4464 0.607143 26.4554 0 25.375 0H14.625C13.5446 0 12.5536 0.607143 12.0714 1.58036ZM37.1429 11.4286H2.85714L4.75 41.6964C4.89286 43.9554 6.76786 45.7143 9.02679 45.7143H30.9732C33.2321 45.7143 35.1071 43.9554 35.25 41.6964L37.1429 11.4286Z" fill="#880518" />
            </g>
            <defs>
                <clipPath id="clip0_275_396">
                    <rect width="40" height="45.7143" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Bin;
