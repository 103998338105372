import * as Types from '../../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GroupJoinRequestCreateMutationVariables = Types.Exact<{
  input: Types.CreateJoinRequestInput;
}>;


export type GroupJoinRequestCreateMutation = { __typename?: 'Mutation', groupJoinRequestCreate: { __typename?: 'CreateJoinRequestFailureView', failureReason: string } | { __typename?: 'CreateJoinRequestSuccessView', groupCode: string } };


export const GroupJoinRequestCreateDocument = gql`
    mutation GroupJoinRequestCreate($input: CreateJoinRequestInput!) {
  groupJoinRequestCreate(input: $input) {
    ... on CreateJoinRequestFailureView {
      failureReason
    }
    ... on CreateJoinRequestSuccessView {
      groupCode
    }
  }
}
    `;
export type GroupJoinRequestCreateMutationFn = Apollo.MutationFunction<GroupJoinRequestCreateMutation, GroupJoinRequestCreateMutationVariables>;

/**
 * __useGroupJoinRequestCreateMutation__
 *
 * To run a mutation, you first call `useGroupJoinRequestCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupJoinRequestCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupJoinRequestCreateMutation, { data, loading, error }] = useGroupJoinRequestCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupJoinRequestCreateMutation(baseOptions?: Apollo.MutationHookOptions<GroupJoinRequestCreateMutation, GroupJoinRequestCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GroupJoinRequestCreateMutation, GroupJoinRequestCreateMutationVariables>(GroupJoinRequestCreateDocument, options);
      }
export type GroupJoinRequestCreateMutationHookResult = ReturnType<typeof useGroupJoinRequestCreateMutation>;
export type GroupJoinRequestCreateMutationResult = Apollo.MutationResult<GroupJoinRequestCreateMutation>;
export type GroupJoinRequestCreateMutationOptions = Apollo.BaseMutationOptions<GroupJoinRequestCreateMutation, GroupJoinRequestCreateMutationVariables>;