import React from 'react';
import { BiddingView } from '../../graphql/types.gen.ts';
import { IResult } from '../../pages/Game/Game.tsx';
import { BiddingUI } from '../BiddingUI/BiddingUI.tsx';
import Answer from './Answer.tsx';

interface BidProps {
    drawnTest: any;
    userBid: BiddingView;
    bestAnswers: BiddingView[];
    handleUserBids: (b: BiddingView) => void;
    result: 'FULL' | 'BAD' | 'HALF' | null;
    handleResult: (r: IResult) => void;
    drawTest: () => void;
    repeatTest: () => void;
    nextTest: () => void;
    recentBids: BiddingView[];
    forLesson?: boolean;
}
export const Bids = (props: BidProps) => {
    const { recentBids, drawnTest, handleUserBids, result, handleResult, userBid, bestAnswers, nextTest, drawTest, repeatTest, forLesson } = props;
    return (
        <div className="bid">
            {!result && <BiddingUI handleUserBids={handleUserBids} recentBids={recentBids} />}
            {result && (
                <div>
                    <Answer userBid={userBid} bestAnswers={bestAnswers} result={result} nextTest={nextTest} drawTest={drawTest} repeatTest={repeatTest} forLesson={forLesson} />
                </div>
            )}
        </div>
    );
};
