import React from 'react';

export const Edit = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_275_407)">
                <path
                    d="M36.8438 1.2832C35.1328 -0.427734 32.3672 -0.427734 30.6563 1.2832L28.3047 3.62695L35.9531 11.2754L38.3047 8.92383C40.0156 7.21289 40.0156 4.44727 38.3047 2.73633L36.8438 1.2832ZM13.4688 18.4707C12.9922 18.9473 12.625 19.5332 12.4141 20.1816L10.1016 27.1191C9.875 27.791 10.0547 28.5332 10.5547 29.041C11.0547 29.5488 11.7969 29.7207 12.4766 29.4941L19.4141 27.1816C20.0547 26.9707 20.6406 26.6035 21.125 26.127L34.1953 13.0488L26.5391 5.39258L13.4688 18.4707ZM7.5 4.58789C3.35938 4.58789 0 7.94727 0 12.0879V32.0879C0 36.2285 3.35938 39.5879 7.5 39.5879H27.5C31.6406 39.5879 35 36.2285 35 32.0879V24.5879C35 23.2051 33.8828 22.0879 32.5 22.0879C31.1172 22.0879 30 23.2051 30 24.5879V32.0879C30 33.4707 28.8828 34.5879 27.5 34.5879H7.5C6.11719 34.5879 5 33.4707 5 32.0879V12.0879C5 10.7051 6.11719 9.58789 7.5 9.58789H15C16.3828 9.58789 17.5 8.4707 17.5 7.08789C17.5 5.70508 16.3828 4.58789 15 4.58789H7.5Z"
                    fill="#C48208"
                />
            </g>
            <defs>
                <clipPath id="clip0_275_407">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
