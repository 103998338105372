import React from 'react'
import './ImageSection.scss'

export const ImageSection = (props) => {
    const { title, desc, variant = 'primary', children } = props

    return (
        <div className={`imageSection imageSection--${variant}`}>
            <>
                {title && <h2 className="imageSection__title">{title}</h2>}
                {desc && <p className="imageSection__desc">{desc}</p>}
            </>
            <div className="container">
                <div className={`row${variant === 'hero' ? ' justify--between' : ' justify--center '}`}>{children}</div>
            </div>
        </div>
    )
}
