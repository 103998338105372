import React from 'react'
import { BidExplanation } from '../../helpers.ts'
import { ResultUnion } from '../Bids/Answer.tsx'
import BrokenHeart from '../BrokenHeart.tsx'
import { SequenceItem } from '../Sequence/SequenceItem.tsx'
import './Explanation.css'
import { BiddingView } from '../../graphql/types.gen.ts'
interface ExplanationProps {
    answers: BiddingView[]
}

export const Explanation = (props: ExplanationProps) => {
    const { answers } = props

    return (
        <div className="explanation">
            <p className="tested__text">PRAWIDŁOWE ODPOWIEDZI</p>
            <div className="explanation__wrapper">
                {answers.map((a, i) => {
                    console.log('Explanation.tsx', 'answers', answers)
                    return (
                        <div className="explanation__item" key={i}>
                            <span>{i > 0 ? ' lub ' : ''}</span>
                            <SequenceItem item={a} classes={`sequence-item-show sequence-item--explanation`} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
