import { ExamVulnerabilityView } from "../../../graphql/types.gen.ts"

export  const vulnerabilityToString = (vulnerability: ExamVulnerabilityView) => {
	switch (vulnerability) {
		case ExamVulnerabilityView.Both:
			return "Wszyscy po partii"
		case ExamVulnerabilityView.EwRed:
			return "Korzystne"
		case ExamVulnerabilityView.None:
			return "Wszyscy przed partią"
		case ExamVulnerabilityView.NsRed:
			return "Niekorzystne"
		default:
			return "Nieokreślone"
	}
}