import React from 'react';

export const Spades = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_51_19)">
                <path d="M15.5 8.2345C15.5 4.61775 8 0.5 8 0.5C8 0.5 0.5 4.61775 0.5 8.2345C0.5 10.3785 2.3065 12.219 4.4845 12.219C5.53275 12.219 6.46575 11.8147 7.16 11.1585L7.0625 11.9847C6.9365 12.882 6.3145 13.7027 5.42175 13.8597L4.25 14.0938V15.5H11.75V14.0938L10.578 13.8593C9.68575 13.7023 9.0635 12.882 8.93725 11.9843L8.84025 11.158C9.534 11.8148 10.467 12.2185 11.5153 12.2185C13.6935 12.2188 15.5 10.3785 15.5 8.2345Z" fill="#231F1F" />
            </g>
            <defs>
                <clipPath id="clip0_51_19">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
