import React from 'react';
import { trumpNameToIcon } from '../../../../components/BiddingUI/BiddingUI.tsx';
import { CardColorView, CardView } from '../../../../graphql/types.gen.ts';
import { numberValueToNumber } from '../../../../helpers.ts';
import './SmallCardsInput.scss';

export enum CardValueView {
    Ace = 'ACE',
    Eight = 'EIGHT',
    Five = 'FIVE',
    Four = 'FOUR',
    Jack = 'JACK',
    King = 'KING',
    Nine = 'NINE',
    Queen = 'QUEEN',
    Seven = 'SEVEN',
    Six = 'SIX',
    Ten = 'TEN',
    Three = 'THREE',
    Two = 'TWO',
}

const CARD_VALUES = [CardValueView.Two, CardValueView.Three, CardValueView.Four, CardValueView.Five, CardValueView.Six, CardValueView.Seven, CardValueView.Eight, CardValueView.Nine, CardValueView.Ten, CardValueView.Jack, CardValueView.Queen, CardValueView.King, CardValueView.Ace].reverse();

interface ISmallCardsInput {
    cards: CardView[];
    toggleCard: (color: CardColorView, value: CardValueView) => void;
    isInCards: (color: CardColorView, value: CardValueView) => boolean;
}

export const SmallCardsInput = (props: ISmallCardsInput) => {
    const { cards, toggleCard, isInCards } = props;
    return (
        <>
            <div className={`smallCardsInput__wrapper ${cards.length === 13 ? 'smallCardsInput__wrapper--max' : ''}`}>
                {Object.keys(CardColorView)
                    .reverse()
                    .map((color) => {
                        return CARD_VALUES.map((value) => {
                            return (
                                <button key={`${color}-${value}`} className={` smallCardsInput__card smallCardsInput__card--${color.toLowerCase()} ${isInCards(color, value) ? '' : 'notActive'}`} onClick={() => toggleCard(color, value)}>
                                    {numberValueToNumber(value)}
                                    <div className="smallCardsInput__suit">{trumpNameToIcon(color.toUpperCase())}</div>
                                </button>
                            );
                        });
                    })}
            </div>
            {cards.length !== 13 && <p className="smallCardsInput__error">Zaznacz 13 kart</p>}
        </>
    );
};
