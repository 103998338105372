import React from 'react';

export const CustomizedTick = (props) => {
    function trimString(str) {
        if (str.length > 12) {
            return str.substring(0, 12) + '...';
        }
        return str;
    }
    const { x, y, payload } = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                {trimString(payload.value)}
            </text>
        </g>
    );
};
