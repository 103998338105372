import React from 'react';

export const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    const ratio = ((payload[1]?.value * 100) / payload[0]?.value || 0).toFixed();
    if (active) {
        return (
            <div className="statistics__tooltip">
                <p className="statistics__label">{`${label}`}</p>
                {payload.map((item: any) => (
                    <p key={item.dataKey} className="label">
                        {`${item.dataKey}: ${item.value}`}
                    </p>
                ))}
                <p className="statistics__label">{ratio}%</p>
            </div>
        );
    }

    return null;
};
