import React from 'react';

export const Clubs = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5 12.5C10.9881 12.5004 10.4823 12.3883 10.0185 12.1716C9.55467 11.9549 9.14416 11.6389 8.81601 11.246C9.2618 12.2572 9.79096 13.2296 10.398 14.153C10.629 14.503 10.378 15 9.96001 15H6.04001C5.62101 15 5.37001 14.503 5.60201 14.153C6.20907 13.2296 6.73823 12.2572 7.18401 11.246C6.80225 11.7022 6.31049 12.0537 5.75517 12.267C5.19986 12.4804 4.59932 12.5487 4.01026 12.4655C3.42121 12.3823 2.86308 12.1504 2.38859 11.7915C1.9141 11.4327 1.5389 10.9588 1.29844 10.4147C1.05797 9.87056 0.960162 9.27412 1.01426 8.68168C1.06835 8.08924 1.27256 7.52037 1.6076 7.02879C1.94264 6.5372 2.39745 6.13913 2.92909 5.87216C3.46072 5.60519 4.05163 5.47814 4.64601 5.503C4.4902 4.98059 4.45858 4.42901 4.55366 3.89221C4.64874 3.35542 4.8679 2.84826 5.19366 2.41115C5.51943 1.97404 5.9428 1.61907 6.43002 1.37453C6.91725 1.13 7.45486 1.00266 8.00001 1.00266C8.54516 1.00266 9.08277 1.13 9.57 1.37453C10.0572 1.61907 10.4806 1.97404 10.8064 2.41115C11.1321 2.84826 11.3513 3.35542 11.4464 3.89221C11.5414 4.42901 11.5098 4.98059 11.354 5.503C11.8193 5.48372 12.2837 5.55749 12.7201 5.71998C13.1566 5.88246 13.5561 6.13041 13.8955 6.44928C14.2349 6.76816 14.5072 7.15155 14.6966 7.577C14.8859 8.00245 14.9884 8.46141 14.9981 8.92698C15.0078 9.39256 14.9246 9.85539 14.7531 10.2884C14.5817 10.7213 14.3256 11.1158 13.9998 11.4485C13.6741 11.7813 13.2852 12.0457 12.8559 12.2262C12.4267 12.4068 11.9657 12.4998 11.5 12.5Z"
                fill="#10530A"
            />
        </svg>
    );
};
