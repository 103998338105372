import React from 'react';
import { BiddingCardColorView } from '../../graphql/types.gen.ts';
import { CardValues } from '../../helpers.ts';
import { trumpNameToIcon } from '../BiddingUI/BiddingUI.tsx';
import './Card.css';

interface CardProps {
    card: { value: number; color: BiddingCardColorView };
    index: number;
}

export const Card = (props: CardProps) => {
    const { card, index } = props;
    if (!card) return;
    return (
        <div key={`card-index${card}`} className={`card${index === 0 ? ' firstCard' : ''}`}>
            <div className={`custom-card custom-card--${card.color.charAt(0)}`}>
                <div className="custom-card__top">
                    <p className="custom-card__number custom-card__top__number">{CardValues[card.value]}</p>
                    <div className="custom-card__top__suit custom-card__suit">{trumpNameToIcon(card.color)}</div>
                </div>
                <div className="custom-card__middle">
                    <p className="custom-card__number custom-card__middle__number">{CardValues[card.value]}</p>
                </div>
                <div className="custom-card__bottom">
                    <p className="custom-card__number custom-card__bottom__number">{CardValues[card.value]}</p>
                    <div className="custom-card__bottom__suit custom-card__suit">{trumpNameToIcon(card.color)}</div>
                </div>
            </div>
        </div>
    );
};
