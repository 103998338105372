import { Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Bids } from '../../../components/Bids/Bids.tsx';
import { Cards } from '../../../components/Cards/Cards.tsx';
import { ComplexSequence } from '../../../components/Sequence/ComplexSequence.tsx';
import { RootState } from '../../../redux/store';
import { useGame } from '../useGame.ts';

interface IGameTestProps {
    id?: string;
    forLesson?: boolean;
    onSubmit?: () => void;
}

export const GameTest = ({ id, forLesson, onSubmit }: IGameTestProps) => {
    const { drawTestById, chosenGroup, handleResult, randomTest, userBids, handleRecentBids, recentBids, drawTest, handleUserBids, lastUserBid, bestAnswers, handleNextTest, repeatTest, southCards, result } = useGame(id, onSubmit);
    const user = useSelector((state: RootState) => state.user);
    const [currentDepth, setCurrentDepth] = useState(0);
    console.log(randomTest, 'randomTest');
    if (!randomTest) return <Skeleton height={790} />;
    return (
        <>
            <div className="game__seq">
                <ComplexSequence result={result} groupCode={chosenGroup} handleResult={handleResult} currentDepth={currentDepth} drawnTest={randomTest} userBids={userBids} handleRecentBids={handleRecentBids} />
                <Bids result={result} drawnTest={randomTest} handleUserBids={handleUserBids} userBid={lastUserBid} bestAnswers={bestAnswers} nextTest={handleNextTest} repeatTest={repeatTest} drawTest={drawTest} recentBids={recentBids} forLesson={forLesson} />
            </div>
            <Cards cards={southCards || Array.from({ length: 13 })} testName={randomTest?.name} />
        </>
    );
};
