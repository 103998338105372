import * as Types from '../../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountActivateMutationVariables = Types.Exact<{
  input: Types.AccountActivationInput;
}>;


export type AccountActivateMutation = { __typename?: 'Mutation', accountActivate: { __typename?: 'AccountActivationErrorView', reason: Types.AccountActivationErrorReason } | { __typename?: 'AccountActivationSuccessView', activatedAt: any } };


export const AccountActivateDocument = gql`
    mutation AccountActivate($input: AccountActivationInput!) {
  accountActivate(input: $input) {
    ... on AccountActivationErrorView {
      reason
    }
    ... on AccountActivationSuccessView {
      activatedAt
    }
  }
}
    `;
export type AccountActivateMutationFn = Apollo.MutationFunction<AccountActivateMutation, AccountActivateMutationVariables>;

/**
 * __useAccountActivateMutation__
 *
 * To run a mutation, you first call `useAccountActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountActivateMutation, { data, loading, error }] = useAccountActivateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountActivateMutation(baseOptions?: Apollo.MutationHookOptions<AccountActivateMutation, AccountActivateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountActivateMutation, AccountActivateMutationVariables>(AccountActivateDocument, options);
      }
export type AccountActivateMutationHookResult = ReturnType<typeof useAccountActivateMutation>;
export type AccountActivateMutationResult = Apollo.MutationResult<AccountActivateMutation>;
export type AccountActivateMutationOptions = Apollo.BaseMutationOptions<AccountActivateMutation, AccountActivateMutationVariables>;