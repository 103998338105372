import React, { useEffect, useState } from 'react';
import { BiddingView } from '../../graphql/types.gen.ts';
import { compareTwoBids } from '../../helpers.ts';
import { trumpNameToIcon } from '../BiddingUI/BiddingUI.tsx';
import { emptyBid } from './ComplexSequence.tsx';

type SequenceItemProps = {
    classes: string;
    item: BiddingView;
    handleClick?: () => void;
};
enum ValueMap {
    ONE,
    TWO,
    THREE,
    FOUR,
    FIVE,
    SIX,
    SEVEN,
}

const specialBidsDict = {
    DOUBLE: 'X',
    REDOUBLE: 'XX',
    PASS: 'PAS',
};

export const SequenceItem = (props: SequenceItemProps) => {
    const { classes, item, handleClick } = props;
    const [show, setShow] = useState(false);
    const delayedClass = () => {
        setTimeout(() => {
            setShow(true);
        }, 20);
    };

    useEffect(() => {
        delayedClass();
    }, []);

    const bidCard = (item: BiddingView) => {
        if (compareTwoBids(item, emptyBid)) {
            return <span className="sequence__special">?</span>;
        } else if (item?.declaration !== 'BID') {
            return <span className="sequence__special ">{specialBidsDict[item?.declaration]}</span>;
        } else if (item?.bidCard && item?.bidCard.color !== 'NO_TRUMPS') {
            return (
                <>
                    <span className="sequence__number ">{ValueMap[item?.bidCard?.value] + 1}</span>
                    {trumpNameToIcon(item?.bidCard?.color)}
                </>
            );
        } else {
            return (
                <>
                    <span className="sequence__number ">{ValueMap[item?.bidCard?.value] + 1}</span>
                    BA
                </>
            );
        }
    };

    return (
        <button
            onClick={() => {
                !!handleClick && handleClick();
            }}
            className={`sequence-item ${classes} ${show ? 'sequence-item-show ' : ''}
            shouldAlert-${item?.shouldAlert}    
            sequence-item--${item?.declaration}
            sequence-item--${item?.bidCard?.color}`}
        >
            <div className="sequence__bid-wrapper">{bidCard(item)}</div>
        </button>
    );
};
