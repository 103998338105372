import React from 'react'
import './Hero.scss'

interface HeroProps {
    title: string
    subtitle?: string
}

const Hero: React.FC<HeroProps> = ({ title, subtitle }) => {
    return (
        <div className="hero">
            <h1 className="hero__title">{title}</h1>
            {subtitle && <p className="hero__desc">{subtitle}</p>}
        </div>
    )
}

export default Hero
