import React from 'react'
import { CardValues } from '../../helpers.ts'
import { Card } from '../Card/Card.tsx'
import './Cards.scss'

export const Cards = (props) => {
    const { cards, small } = props

    return (
        <>
            <div className={`cards-wrapper${small ? ' small' : ''}`}>
                <div className={`cards${small ? ' small' : ''}`}>{cards && cards.map((card, index) => <Card card={card} index={index} key={index} />)}</div>
            </div>
        </>
    )
}
