import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import biddingReducer from './biddingSlice.ts';
import userReducer from './userSlice.ts';

const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer = combineReducers({
    user: userReducer,
    bidding: biddingReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// // Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
    reducer: persistedReducer,
});
export const persistor = persistStore(store);
