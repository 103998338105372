import React, { useEffect, useMemo, useState } from 'react';
import { BiddingDeclarationView, BiddingView, ExamView } from '../../graphql/types.gen.ts';
import { compareTwoBids, santizeId } from '../../helpers.ts';
import { IResult } from '../../pages/Game/Game.tsx';
import { Seats } from './Seats.tsx';
import './Sequence.scss';
import { SequenceItem } from './SequenceItem.tsx';

interface ComplexSequenceProps {
    drawnTest: ExamView;
    userBids: BiddingView[];
    handleResult: (r: IResult) => void;
    handleRecentBids: (b: BiddingView[]) => void;
    groupCode: string;
    currentDepth: number;
    result: IResult;
}

export const emptyBid: BiddingView = {
    bidCard: {
        color: null,
        value: null,
    },
    declaration: BiddingDeclarationView.Bid,
    shouldAlert: false,
};

export const ComplexSequence = (props: ComplexSequenceProps) => {
    const { groupCode, drawnTest, userBids, handleResult, handleRecentBids, result } = props;
    // const { drawnTest, currentDepth, wholeSequence, testDepth } = useSelector(
    //     (state: RootState) => state.bidding
    //   );
    //TODO: maybe you can remove currentDepth
    const currentDepth = userBids.length;

    const [userAnswersWithNextSequences, setUserAnswersWithNextSequences] = useState<BiddingView[]>([]);

    useEffect(() => {
        if (result === 'BAD') return;
        const userWithAns = userBids.length
            ? userBids.flatMap((item, index) => {
                  return index === userBids.length - 1 && index !== drawnTest.definition.answers.length - 1 ? [item, ...drawnTest.definition.answers[index].biddingSequence, emptyBid] : [item, ...drawnTest.definition.answers[index].biddingSequence];
              })
            : [emptyBid];
        setUserAnswersWithNextSequences(userWithAns);
    }, [userBids, drawnTest]);

    const allBids = useMemo(() => [...drawnTest.definition.initialBiddingSequence, ...userAnswersWithNextSequences], [userAnswersWithNextSequences]);
    //   const { testSequence, reversedDepth, depth, complexAnswers } = props;

    //   const testSequence = drawnTest?.wholeSeqence;
    // const depth = drawnTest?.depth
    // const currentAnswers = drawnTest?.currentAnswers
    // const currentAnswers = reversedDepth;
    // const currentHalfAnswers = drawnTest?.currentHalfAnswers
    const [isInitLoading, setisInitLoading] = useState(true);
    const [wholeSeqAnimation, setWholeSeqAnimation] = useState(false);

    const classForSide = {
        0: 'W',
        1: 'S',
        2: 'E',
        3: 'N',
    };

    const classForStarted = {
        0: 'W',
        1: 'S',
        2: 'E',
        3: 'N',
    };

    const initAnimate = () => {
        // console.log("run init animate");
        setisInitLoading(true);
        setTimeout(() => {
            setisInitLoading(false);
        }, 500);
    };

    //   const handleReanimate = () => {
    //     animate();
    //     setWholeSeqAnimation(true);
    //     setTimeout(() => {
    //       setWholeSeqAnimation(false);
    //     }, 500 * testSequence?.length);
    //   };

    useEffect(() => {
        // console.log(isInitLoading);
    }, [isInitLoading]);

    useEffect(() => {
        // console.log("allBids", allBids);
        // console.log("wholeSequence", wholeSequence);
        // console.log(
        //   "currentDepth < testDepth",
        //   currentDepth,
        //   testDepth,
        //   currentDepth < testDepth
        // );
        if (currentDepth === 0) {
            initAnimate();
        }
        // console.log("changed all bids");
    }, [drawnTest]);

    const started = allBids ? classForStarted[allBids.length % 4] : null;

    const getColorByType = (type: string): string => {
        switch (type) {
            case 'PAS':
                return 'pass-green';
            case 'X':
                return 'ktr-red';
            case 'XX':
                return 'rktr-blue';
            default:
                return '';
        }
    };

    useEffect(() => {
        handleRecentBids(allBids);
    }, [allBids]);

    const orderedBidsWithClasses = useMemo(() => {
        console.log('result', result);
        if (!allBids) return null;
        return allBids.map((item, index) => {
            // const x = ((allBids.length - 1) % 4) + currentDepth * 4
            const y = index + 4 - (allBids.length % 4);
            const z = allBids.length % 4 || 4;
            const seq0123 = index < allBids.length % 4 ? index : y;
            const isEmptyBid = compareTwoBids(item, emptyBid);
            //   console.log(testSequence);
            //   console.log(depth, index, x, allBids.length % 4, currentDepth);
            //   console.log(item);

            const classes = `sequence-item 
            shouldAlert-${item?.shouldAlert}    
          seq0123-${seq0123}
          index-${index}
          shouldShow-${index < z + 4 * currentDepth}
          ${classForSide[(allBids.length - index) % 4]}-bid 
          ${getColorByType(item?.bidCard?.color)}
          sequence-item-depth-${Math.floor(index / 4)}
          complex-sequence-item-${!wholeSeqAnimation ? seq0123 % 4 : ''} 
          ${isEmptyBid ? ' emptyBid' : ''}`;
            return <SequenceItem key={drawnTest?.id + item.bidCard?.color + item.bidCard?.value + index} item={item} classes={classes} />;
        });
    }, [allBids, currentDepth, isInitLoading]);

    console.log('allBids', allBids);
    return (
        <>
            <div className={`bids-space current-depth-${0} v-${santizeId(drawnTest?.vulnerability)}`} id="bids-space">
                <div className="test-sequence">
                    <Seats started={started} />
                    {!!allBids && !!orderedBidsWithClasses && orderedBidsWithClasses}
                </div>
            </div>
        </>
    );
};
