import { useEffect, useState } from 'react';
import { BiddingView, ExamView } from '../../../graphql/types.gen.ts';
import { IBidReference } from '../components/BackofficeTestEditMode.js';

export interface IBackofficeSeqItem {
    bids: BiddingView[];
    answers: BiddingView[];
    halfAnswers: BiddingView[];
}
export const useGetBackofficeBidsArray = (test: ExamView) => {
    const [modArr, setModArr] = useState<IBackofficeSeqItem[]>([]);
    const reverseTransformOfTest = (arr: IBackofficeSeqItem[]) => {
        const initialBiddingSequence = arr[0]?.bids.length ? removeTypenames(arr[0]?.bids).map((item) => (item ? { ...item } : null)) : [];
        return {
            definition: {
                initialBiddingSequence,
                answers: arr.map((item, depth) => {
                    return {
                        matchingAnswer: { ...removeTypenames(item.answers[0]) },
                        halfAnswers: removeTypenames(item.halfAnswers).map((item) => (item ? { ...item } : null)),
                        biddingSequence: modArr[depth + 1]?.bids.length ? removeTypenames(modArr[depth + 1]?.bids).map((item) => (item ? { ...item } : null)) : [],
                    };
                }),
            },
        };
    };
    const initTransformOfTest = (test: ExamView) => {
        const preBids = Array.from<undefined>({
            length: 3 - test.definition.initialBiddingSequence.length,
        });
        setModArr([
            ...test.definition.answers.map((obj, index) => {
                return { bids: index === 0 ? [...preBids, ...test.definition.initialBiddingSequence] : test.definition.answers[index - 1].biddingSequence, answers: [obj.matchingAnswer], halfAnswers: obj.halfAnswers };
            }),
        ]);
    };

    const validateExamObject = (examObject: IBackofficeSeqItem[]) => {
        if (!examObject || !examObject[0]?.answers) return { validate: false, c: '1' };

        for (let i = 0; i < examObject.length; i++) {
            if (!examObject[i].answers[0] || examObject[i]?.bids.length !== 3) return { validate: false, c: '2' };
            if (i >= 1 && examObject[i].bids.some((bid) => !bid)) return { validate: false, c: '3' };
            if (i === 0) {
                for (let j = 0; j < examObject[0].bids.length; j++) {
                    if (examObject[0].bids[j]) {
                        for (let k = j; k < examObject[0].bids.length; k++) {
                            if (!examObject[0].bids[k]) return { validate: false, c: '4' };
                        }
                    }
                }
            }
        }

        return { validate: true, c: null };
    };
    // const testToHumanReadableArray = (test: ExamView) => {
    //     const depth = Math.max(
    //         ...test.definition.answers.map((item) => {
    //             return [...item.artificialId.matchAll(/\./g)].length
    //         })
    //     )
    //     const firstAnswer = test.definition.answers
    //         .filter((item) => {
    //             return [...item.artificialId.matchAll(/\./g)].length === 1 && item.correctness === AnswerCorrectness.Full
    //         })
    //         .map((item) => item.matchingAnswer)
    //     const firstHalfAnswer = test.definition.answers
    //         .filter((item) => {
    //             return [...item.artificialId.matchAll(/\./g)].length === 1 && item.correctness === AnswerCorrectness.Half
    //         })
    //         .map((item) => item.matchingAnswer)
    //     const preBids = Array.from<undefined>({
    //         length: 3 - test.definition.initialBiddingSequence.length,
    //     })
    //     const arr: IBackofficeSeqItem[] = [
    //         {
    //             bids: [...preBids, ...test.definition.initialBiddingSequence],
    //             answers: firstAnswer,
    //             halfAnswers: firstHalfAnswer,
    //         },
    //     ]
    //     for (let i = 1; i < depth; i++) {
    //         const bids = [
    //             ...test.definition.answers.filter((item) => {
    //                 return [...item.artificialId.matchAll(/\./g)].length === i && item.correctness === AnswerCorrectness.Full
    //             })[0].biddingSequence,
    //         ]
    //         const answers = test.definition.answers
    //             .filter((item) => {
    //                 return [...item.artificialId.matchAll(/\./g)].length === i + 1 && item.correctness === AnswerCorrectness.Full
    //             })
    //             .map((item) => item.matchingAnswer)
    //         const halfAnswers = test.definition.answers
    //             .filter((item) => {
    //                 return [...item.artificialId.matchAll(/\./g)].length === i + 1 && item.correctness === AnswerCorrectness.Half
    //             })
    //             .map((item) => item.matchingAnswer)
    //         const bidLine = {
    //             bids,
    //             answers,
    //             halfAnswers,
    //         }
    //         arr.push(bidLine)
    //     }
    //     return arr
    // }

    useEffect(() => {
        initTransformOfTest(test);
    }, [test]);

    function removeTypenames(obj: any): any {
        if (Array.isArray(obj)) {
            return obj.map(removeTypenames);
        } else if (obj !== null && typeof obj === 'object') {
            const newObj: any = {};
            for (const key of Object.keys(obj)) {
                if (key !== '__typename') {
                    newObj[key] = removeTypenames(obj[key]);
                }
            }
            return newObj;
        } else {
            return obj;
        }
    }
    // useEffect(() => {
    //     console.log(modArr)
    // }, [modArr])

    const updateArr = (bid: BiddingView | null, bidRef: IBidReference) => {
        setModArr((prev) => {
            const newArr = [...prev];
            while (bidRef.index >= newArr.length) {
                newArr.push({ bids: [null, null, null], answers: [null], halfAnswers: [null] });
            }
            newArr[bidRef.index][bidRef.type][bidRef.innerIndex] = bid;
            for (let i = newArr.length - 1; i >= 0; i--) {
                if (newArr[i].bids.every((bid) => bid === null) && newArr[i].answers.every((answer) => answer === null) && newArr[i].halfAnswers.every((halfAnswer) => halfAnswer === null)) {
                    newArr.pop();
                } else {
                    if (newArr[i].answers.every((answer) => answer === null) && newArr[i].answers.length >= 1) {
                        newArr[i].answers.pop();
                    }
                    if (newArr[i].halfAnswers.every((halfAnswer) => halfAnswer === null) && newArr[i].halfAnswers.length >= 1) {
                        newArr[i].halfAnswers.pop();
                    }
                }
            }
            return newArr;
        });
    };
    const humanReadableArrayToTest = (arr: IBackofficeSeqItem[]) => {
        const generateArtificialId = (depth: number, index: number) => {
            return Array(depth)
                .fill(index + 1)
                .join('.');
        };

        const answers = [];

        arr.forEach((item, depth) => {
            item.answers.forEach((matchingAnswer, index) => {
                answers.push({
                    artificialId: generateArtificialId(depth + 1, index),
                    biddingSequence: item.bids,
                    correctness: AnswerCorrectness.Full,
                    matchingAnswer: matchingAnswer,
                });
            });

            item.halfAnswers.forEach((matchingAnswer, index) => {
                answers.push({
                    artificialId: generateArtificialId(depth + 1, item.answers.length + index),
                    biddingSequence: item.bids,
                    correctness: AnswerCorrectness.Half,
                    matchingAnswer: matchingAnswer,
                });
            });
        });

        const initialBiddingSequence = arr[0].bids.slice(-3);

        return {
            definition: {
                initialBiddingSequence,
                answers,
            },
        };
    };

    function transformArrToMutationInput(inputObject) {
        const transformedAnswers = inputObject.answers.map((answer) => {
            const subAnswers = answer.subAnswerIds.map((subAnswerId) => {
                const subAnswer = inputObject.answers.find((ans) => ans.artificialId === subAnswerId);
                return {
                    biddingSequence: subAnswer.biddingSequence.map(({ __typename, ...bid }) => {
                        return { ...bid };
                    }),
                    correctness: subAnswer.correctness,
                    matchingAnswer: subAnswer.matchingAnswer,
                    subAnswers: [],
                };
            });

            return {
                biddingSequence: answer.biddingSequence.map(({ __typename, ...bid }) => {
                    return { ...bid };
                }),
                correctness: answer.correctness,
                matchingAnswer: answer.matchingAnswer,
                subAnswers: subAnswers,
            };
        });

        return {
            initialBiddingSequence: inputObject.initialBiddingSequence.map(({ __typename, ...bid }) => {
                return { ...bid };
            }),
            cards: inputObject.cards,
            answers: transformedAnswers,
        };
    }

    const answersToSave = {
        initialBiddingSequence: reverseTransformOfTest(modArr).definition.initialBiddingSequence,
        answers: reverseTransformOfTest(modArr).definition.answers,
    };
    // console.log(answersToSave)
    //   const transformArrToMutationInput = (passedArr) => {
    //     let singleArray = [];

    //     passedArr &&
    //       passedArr.forEach((obj, index) => {
    //         let bids = [];
    //         let answers = [];
    //         let halfAnswers = [];
    //         obj.bids.forEach((item) => {
    //           item && bids.push(item);
    //         });

    //         if (obj.answers.length) {
    //           obj.answers.forEach((item) => {
    //             answers.push(item);
    //           });
    //         }

    //         if (obj.halfAnswers.length) {
    //           obj.halfAnswers.forEach((item) => {
    //             halfAnswers.push(item);
    //           });
    //         }
    //         singleArray.push({
    //           bids: bids,
    //           answers: answers,
    //           halfAnswers: halfAnswers,
    //         });
    //       });

    //     return singleArray;
    //   };

    const resetArr = () => {
        initTransformOfTest(test);
    };
    return { arr: modArr, updateArr, transformArrToMutationInput, resetArr, humanReadableArrayToTest, answersToSave, validateExamObject };
};
