import { ArrowBack, ArrowForward } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router';
import './LessonItem.scss';
interface ILessonItemProps {
    title: string;
    add?: boolean;
    url: string;
    status?: 'available' | 'done' | 'unavailable';
    handleMoveBack?: () => void;
    handleMoveForward?: () => void;
    isInstructor?: boolean;
}

export const LessonItem = ({ title, add, url, status, handleMoveForward, handleMoveBack, isInstructor }: ILessonItemProps) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(url, { lesson: true });
    };

    return (
        <div className="lessonItem__wrapper">
            <button className={`lessonItem ${add ? 'lessonItem--add' : ''} lessonItem--${status} ${isInstructor ? 'instructor' : ''}`} onClick={handleClick}>
                <h2 className="lessonItem__title">{title}</h2>
            </button>
            {!add && isInstructor && (
                <>
                    {handleMoveBack && (
                        <button className="lessonItem__arrow lessonItem__arrow--left" onClick={handleMoveBack}>
                            <ArrowBack />
                        </button>
                    )}
                    {handleMoveForward && (
                        <button className="lessonItem__arrow lessonItem__arrow--right" onClick={handleMoveForward}>
                            <ArrowForward />
                        </button>
                    )}
                </>
            )}
        </div>
    );
};
