import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { CustomApolloProvider } from './graphql/client.tsx';
import './index.css';
import { persistor, store } from './redux/store.ts';
import reportWebVitals from './reportWebVitals.js';
import { ROUTER } from './router.tsx';
import { THEME } from './theme.ts';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={THEME}>
                <CustomApolloProvider>
                    <RouterProvider router={ROUTER} />
                </CustomApolloProvider>
            </ThemeProvider>
        </PersistGate>
    </Provider>
);

reportWebVitals();
