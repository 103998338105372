import React from 'react';
import { Clubs } from '../../assets/Clubs.tsx';
import { Diamonds } from '../../assets/Diamonds.tsx';
import { Hearts } from '../../assets/Hearts.tsx';
import { LoadingLogo } from '../../assets/LoadingLogo.tsx';
import { LoadingLogoGreen } from '../../assets/LoadingLogoGreen.tsx';
import './Loading.scss';

export const Loading = ({ blur = false }) => {
    return (
        <div className={`loading-overlay ${blur ? 'loading-overlay--blurred' : ''}`}>
            <div className="loading-img-wrapper">
                <>
                    {!blur ? <LoadingLogo /> : <LoadingLogoGreen />}
                    <div className="loading-suit loading-suit--HEARTS">
                        <Hearts />
                    </div>
                    <div className="loading-suit loading-suit--DIAMONDS">
                        <Diamonds />
                    </div>
                    <div className="loading-suit loading-suit--CLUBS">
                        <Clubs />
                    </div>
                </>
            </div>
        </div>
    );
};
