import * as Yup from "yup";
export const signupSchema = Yup.object().shape({
  username: Yup.string()
    .required("Nick jest wymagany")
    .min(3, "Nick jest za krótki"),
  email: Yup.string()
    .email("Wprowadź poprawny adres email")
    .required("Email jest wymagany"),
  password: Yup.string()
    .required("Hasło jest wymagane")
    .min(8, "Hasło jest za krótkie"),
});
