import React from 'react';
import './Button.scss';

export const Button = (props) => {
    const { text, onClick, customClass, variant = '', Icon, color, children, ...rest } = props;
    return (
        <button {...rest} className={`${customClass ? customClass : ''} button--${variant} button`} onClick={onClick && onClick}>
            <>
                {text}
                {Icon && <Icon />}
                {children && children}
            </>
        </button>
    );
};
