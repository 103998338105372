import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Crowns } from '../../assets/Crowns.tsx';
import { Logo } from '../../assets/Logo.tsx';
import { Button } from '../../components/Button/Button.tsx';
import { Footer } from '../../components/Footer/Footer.tsx';
import { Header } from '../../components/Header/Header.tsx';
import Linkedin from '../../icons/Linkedin.tsx';
import './Home.scss';
import { Demo } from './components/Demo.tsx';
import { FlyingBids } from './components/FlyingBids.tsx';
import { ImageSection } from './components/ImageSection/ImageSection.tsx';
import { Section } from './components/Section/Section.tsx';

export const Home = () => {
    const navigate = useNavigate();

    const goToGame = () => {
        navigate('/game');
    };
    const goToBackofficeInfo = () => {
        navigate('/backoffice-info');
    };

    return (
        <div className="home">
            <Header />
            <div className="section__hero-wrapper">
                <img className="section__hero__bg" src="./images/hero-bg.png" alt="Cards" />
                <div className="section__hero">
                    <div className="home__hero">
                        <div className="home__hero--first">
                            <span>Graj.</span>
                        </div>
                        <div className="home__hero--sec">
                            <span>Licytuj.</span>
                        </div>
                        <div className="home__hero--third">
                            <span>Wygrywaj.</span>
                        </div>
                    </div>
                    <p className="section__desc">
                        <span className="section__desc--licytapka">Licytapka</span> to Twoja partnerka <br />w nauce licytacji brydżowych
                    </p>
                    <Button onClick={goToGame} text="Rozpocznij grę" />
                </div>
            </div>
            <Demo />
            <div className="home__section home__section--bids">
                <FlyingBids direction="right" />
                <div className="home__logo-wrapper">
                    <Logo fill="#000" />
                    <p className="home__logo-desc">Twoja partnerka w licytacji</p>
                </div>
                <FlyingBids direction="left" />
            </div>
            <ImageSection variant="white">
                <img src="./images/custom-cards7.jpg" alt="seq-bids" />
                <div className="imageSection__content">
                    <h3 className="section__title">Nauka licytacji</h3>
                    <p className="section__desc">Licytacje w grupie ogólnej są przygotowane na podstawie systemu Wspólny Język. Zapoznaj się z nim przed rozpoczęciem testów. </p>
                    <Button variant="ghost-black" onClick={goToGame} text="Ucz się" />
                </div>
            </ImageSection>

            <ImageSection variant="red-noPb">
                <div className="imageSection__content">
                    <h3 className="section__title">Zbieraj punkty</h3>
                    <p className="section__desc">Za poprawnie wykonane testy otrzymasz punkty i odznaki! Zbieraj punkty, aby przejść do kolejnej ligi</p>
                    <Button variant="ghost-white-red" onClick={() => {}} text="Zobacz swoje statystyki" />
                </div>
                <Crowns />
            </ImageSection>
            <ImageSection>
                <img className="home__backoffice" src="./images/tests.png" alt="" />
                <div className="imageSection__content">
                    <h3 className="section__title">Uczysz brydża?</h3>
                    <p className="section__desc">Jeżeli chcesz tworzyć własne przypadki testowe, przeczytaj instrukcję i utwórz własną grupę</p>

                    <Button variant="ghost-white-green" onClick={goToBackofficeInfo} text="Stwórz własne testy" />
                </div>
            </ImageSection>
            <ImageSection variant="bg">
                <div className="imageSection__content">
                    <h3 className="section__title">Kontakt</h3>
                    <p className="section__desc">Masz pomysł na fajny projekt? Chcesz się dowiedzieć więcej o powstaniu Licytapki? Skontaktuj się ze mną</p>
                </div>
                <div className="imageSection__card">
                    <img className="imageSection__card__magda" src="./images/magda-keyboard-black.png" alt="" />
                    <Logo fill="#000" />
                    <p className="imageSection__desc">magdalena@licytapka.pl</p>
                    <a className="imageSection__desc" href="https://www.linkedin.com/in/magdapaluch/">
                        <Linkedin />
                        magdapaluch
                    </a>
                </div>
            </ImageSection>
            <Section variant="gold">
                <h3 className="section__title">Podziękowania</h3>
                <p>
                    Szymon Ostrowski (brydż),{' '}
                    <a className="home__link" target="_blank" href="https://www.linkedin.com/in/thebartekbanach/" rel="noreferrer">
                        Bartek Banach (backend, CI/CD).
                    </a>{' '}
                    Specjalne podziękowania dla grupy <b>MOST SIG</b>.
                </p>
            </Section>
            <Footer />
        </div>
    );
};
