import React from 'react';

export const Hearts = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_21_391)">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.75 0.5C10.9793 0.5 9.55294 1.42099 8.48353 2.61749C7.44441 1.37999 6.02066 0.5 4.25 0.5C1.75631 0.5 0 2.51799 0 4.71899C0 5.89949 0.513719 6.74949 1.07631 7.54849L7.68134 15.0015C8.42244 15.656 8.56269 15.656 9.30378 15.0015L15.9237 7.54849C16.5856 6.74949 17 5.89949 17 4.71899C17 2.51799 15.2437 0.5 12.75 0.5Z" fill="#A71127" />
            </g>
            <defs>
                <clipPath id="clip0_21_391">
                    <rect width="17" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
