import { Pagination } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Button } from '../../components/Button/Button.tsx';
import { Footer } from '../../components/Footer/Footer.tsx';
import { Header } from '../../components/Header/Header.tsx';
import { Loading } from '../../components/Loading/Loading.tsx';
import { ExamVulnerabilityView } from '../../graphql/types.gen.ts';
import { useValidateToken } from '../../helpers/useValidateToken.ts';
import type { RootState } from '../../redux/store.ts';
import { useGroupsLazyQuery } from '../Game/game.gen.ts';
import './Backoffice.scss';
import { useExamRemoveMutation } from './backoffice.gen.ts';
import { BackofficeTest } from './components/BackofficeTest.tsx';
export const LIMIT = 10;
export const EMPTY_TEST = {
    definition: {
        answers: [],
        cards: [],
        initialBiddingSequence: [],
        __typename: 'ExamDefinitionView',
    },
    difficultyLevel: '',
    id: '',
    name: '',
    tags: [],
    updatedAt: '',
    vulnerability: ExamVulnerabilityView.NotSpecified,
    revisionId: '',
    __typename: 'ExamView',
};

const Backoffice = () => {
    const backofficeTests = useRef<null | HTMLDivElement>(null);
    const user = useSelector((state: RootState) => state.user);
    const [editedTestId, setEditedTestId] = useState<string | null>(null);
    const [examRemoveMutation] = useExamRemoveMutation();
    const [fetchGroups, { data, loading, error }] = useGroupsLazyQuery();
    const [offset, setOffset] = useState(0);
    const [emptyTest, setEmptyTest] = useState(false);
    const [page, setPage] = useState(1);
    const fetchData = () => {
        fetchGroups({
            fetchPolicy: 'no-cache',
            variables: {
                input: {
                    pagination: {
                        limit: LIMIT,
                        offset: offset || 0,
                    },
                    filters: {
                        difficultyLevel: [],
                        tags: [],
                    },
                },
            },
        });
    };

    useEffect(() => {
        fetchData();
    }, [offset]);

    const total = data?.me.groups.filter((group) => group.code === user.chosenGroup)[0]?.exams.total;

    const count = Math.ceil(total / LIMIT);
    const onSave = () => {
        toast.success('Test zaktualizowany pomyślnie');
        tests?.items.length === 10 && emptyTest && handlePageChange(null, page + 1);
        setEmptyTest(false);
        fetchData();
    };
    const handlePageChange = (_e: any, value: number) => {
        setPage(value);
        setOffset((value - 1) * LIMIT);
        setEmptyTest(false);
    };
    const handleRemoveClick = async (id: string) => {
        const response = await examRemoveMutation({
            variables: {
                input: {
                    examId: id,
                    groupCode: user.chosenGroup,
                },
            },
        });
        if (response.data?.examDelete?.reason === null) {
            toast.success('Test został usunięty');
            fetchData();
            tests?.items.length === 1 && handlePageChange(null, page - 1);
        } else {
            toast.error('Nie udało się usunąć testu');
        }
    };

    const tests = data?.me.groups.filter((group) => group.code === user.chosenGroup)[0]?.exams;

    const addEmptyTest = () => {
        handlePageChange(null, count);
        setEmptyTest(true);
        scrollToBottom();
    };
    useValidateToken();

    const scrollToBottom = () => {
        backofficeTests.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // const memoizedTests = useMemo(() => tests?.items || [], [tests]);

    return (
        <div className="backoffice">
            <ToastContainer />
            {loading && <Loading blur />}
            <Header />
            <div className="wrapper">
                <div className="backoffice__tests">
                    {!emptyTest && isEmpty(tests?.items) && !loading && (
                        <div>
                            <h2 className="backoffice__noTests">Dodaj swój pierwszy test!</h2>
                            <img className="backoffice__help" src={window.location.origin + '/images/backoffice-help.png'} alt="Pomoc" />
                        </div>
                    )}
                    {!emptyTest && !loading && <Button customClass="backoffice__add" variant="ghost-gold" text={`${isEmpty(tests?.items) ? 'Rozumiem' : 'Dodaj'}`} onClick={addEmptyTest} />}
                    {<div className="backoffice__pagination">{!isEmpty(tests?.items) && <Pagination count={count} onChange={handlePageChange} page={page} />}</div>}

                    {tests?.items.map((test) => (
                        <BackofficeTest
                            key={test.id}
                            index={tests?.items.indexOf(test) + 1 + offset}
                            test={test}
                            onSave={onSave}
                            editMode={editedTestId === test.id}
                            handleLeaveEditClick={() => {
                                setEditedTestId(null);
                            }}
                            handleEditClick={() => {
                                setEditedTestId(test.id);
                            }}
                            handleRemoveClick={handleRemoveClick}
                        />
                    ))}
                    {emptyTest && (
                        <BackofficeTest
                            index={tests?.items.length + 1 + offset}
                            key="empty"
                            test={{ ...EMPTY_TEST, name: `test` }}
                            onSave={onSave}
                            editMode={true}
                            handleLeaveEditClick={() => {
                                setEmptyTest(false);
                                setEditedTestId(null);
                            }}
                            handleEditClick={() => {}}
                            handleRemoveClick={handleRemoveClick}
                        />
                    )}
                </div>
                <div ref={backofficeTests} />
                {!emptyTest && !isEmpty(tests?.items) && !loading && <Button customClass="backoffice__add" variant="ghost-gold" text="Dodaj" onClick={addEmptyTest} />}
            </div>
            {<div className="backoffice__pagination">{!isEmpty(tests?.items) && <Pagination count={count} onChange={handlePageChange} page={page} />}</div>}
            <Footer />
        </div>
    );
};

export default Backoffice;
