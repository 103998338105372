import * as Types from '../../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GroupCreateMutationVariables = Types.Exact<{
  input: Types.GroupCreateInput;
}>;


export type GroupCreateMutation = { __typename?: 'Mutation', groupCreate: { __typename?: 'GroupCreateFailureView', reason: Types.GroupCreateFailureReason } | { __typename?: 'GroupCreateSuccessView', groupCode: string } };


export const GroupCreateDocument = gql`
    mutation GroupCreate($input: GroupCreateInput!) {
  groupCreate(input: $input) {
    ... on GroupCreateFailureView {
      reason
    }
    ... on GroupCreateSuccessView {
      groupCode
    }
  }
}
    `;
export type GroupCreateMutationFn = Apollo.MutationFunction<GroupCreateMutation, GroupCreateMutationVariables>;

/**
 * __useGroupCreateMutation__
 *
 * To run a mutation, you first call `useGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupCreateMutation, { data, loading, error }] = useGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupCreateMutation(baseOptions?: Apollo.MutationHookOptions<GroupCreateMutation, GroupCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GroupCreateMutation, GroupCreateMutationVariables>(GroupCreateDocument, options);
      }
export type GroupCreateMutationHookResult = ReturnType<typeof useGroupCreateMutation>;
export type GroupCreateMutationResult = Apollo.MutationResult<GroupCreateMutation>;
export type GroupCreateMutationOptions = Apollo.BaseMutationOptions<GroupCreateMutation, GroupCreateMutationVariables>;