import React from 'react';
import './IconButton.scss';

interface IIconButton {
    onClick: () => void;
    Icon: JSX.Element;
    text: string;
    disabled?: boolean;
}
export const IconButton = (props: IIconButton) => {
    const { onClick, Icon, text, disabled } = props;
    return (
        <button disabled={disabled} className="iconButton" onClick={onClick}>
            <div className="iconButton__icon">{Icon}</div>
            <p className="iconButton__text">{text}</p>
        </button>
    );
};
