import { BiddingCardColorView, BiddingDeclarationView, BiddingView } from '../../../graphql/types.gen.ts';
import { bidValueToNumber, compareTwoBids } from '../../../helpers.ts';
import { emptyBid } from '../../Sequence/ComplexSequence.tsx';
import { TRUMPS } from '../BiddingUI.tsx';

export const useBidLimitations = (wholeSequence: BiddingView[], editMode: boolean) => {
    if ((wholeSequence.length === 1 && compareTwoBids(wholeSequence[0], emptyBid)) || editMode) {
        return {
            canUseKtr: true,
            canUseRktr: true,
            lowestPossibleNumber: 1,
            lowestPossibleTrump: BiddingCardColorView.Clubs,
        };
    }
    const isNormalBid = (item) => {
        return item?.declaration === 'BID';
    };

    const currentHighestItem = wholeSequence
        .filter((item) => {
            return isNormalBid(item) && !compareTwoBids(item, emptyBid);
        })
        .slice(-1)[0];

    const lowestPossibleNumber: number = currentHighestItem?.bidCard.color === 'NO_TRUMPS' ? bidValueToNumber(currentHighestItem?.bidCard.value) + 1 : bidValueToNumber(currentHighestItem?.bidCard.value);

    const lowestPossibleTrump = currentHighestItem?.bidCard.color === 'NO_TRUMPS' ? -1 : TRUMPS.indexOf(currentHighestItem?.bidCard.color) + 1;

    const canUseKtr = () => {
        return isNormalBid([...wholeSequence].slice(-1)[0]) || (isNormalBid([...wholeSequence].slice(-3)[0]) && [...wholeSequence].slice(-2)[0]?.declaration === BiddingDeclarationView.Pass && [...wholeSequence].slice(-1)[0]?.declaration === BiddingDeclarationView.Pass);
    };
    const canUseRktr = () => {
        return [...wholeSequence].slice(-1)[0]?.declaration === BiddingDeclarationView.Double || ([...wholeSequence].slice(-3)[0]?.declaration === BiddingDeclarationView.Double && [...wholeSequence].slice(-2)[0]?.declaration === BiddingDeclarationView.Pass && [...wholeSequence].slice(-1)[0]?.declaration === BiddingDeclarationView.Pass);
    };

    return {
        canUseKtr,
        canUseRktr,
        lowestPossibleNumber,
        lowestPossibleTrump,
    };
};
