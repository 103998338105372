import 'ckeditor5/ckeditor5.css';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Footer } from '../../components/Footer/Footer.tsx';
import { Header } from '../../components/Header/Header.tsx';
import Hero from '../../components/Hero/Hero.tsx';
import { Loading } from '../../components/Loading/Loading.tsx';
import { GroupMemberRoleView } from '../../graphql/types.gen.ts';
import { useValidateToken } from '../../helpers/useValidateToken.ts';
import { LessonItem } from './LessonItem/LessonItem.tsx';
import './Lessons.scss';
import { useGetLessonsFromGroupLazyQuery, useLessonOrderChangeMutation } from './lessons.gen.ts';

export const Lessons = () => {
    const { chosenGroup } = useSelector((state) => state.user);
    const [lessonOrderChangeMutation] = useLessonOrderChangeMutation();

    const [getLessons, { data: lessons, loading, error }] = useGetLessonsFromGroupLazyQuery({
        fetchPolicy: 'no-cache',
        variables: {
            lessonsInput2: {
                filters: {
                    difficultyLevel: [],
                    tags: [],
                },
                pagination: {
                    limit: 50,
                    offset: 0,
                },
            },
            code: chosenGroup,
        },
    });
    useEffect(() => {
        getLessons();
    }, []);
    useValidateToken();
    const isInstructor = lessons?.group?.me?.role === GroupMemberRoleView.Instructor;

    const lessonsArray = lessons?.group.lessons.items || [];

    let latestDoneIndex = -1;
    for (let i = 0; i < lessonsArray.length; i++) {
        if (lessonsArray[i].isHomeworkDone || !lessonsArray[i].hasHomework) {
            latestDoneIndex = i;
        }
    }

    const updatedLessons = lessonsArray.map((lesson, index) => {
        let status = 'unavailable';
        if (index <= latestDoneIndex) {
            status = 'done';
        } else if (index === latestDoneIndex + 1) {
            status = 'available';
        }
        return { ...lesson, status };
    });

    console.log(updatedLessons);
    const handleOrderChange = (direction: 'forward' | 'back', index) => {
        const lessonSlug = updatedLessons[index].slug;
        console.log(updatedLessons[index - 2]?.slug, updatedLessons[index + 1]?.slug);
        lessonOrderChangeMutation({
            variables: {
                input: {
                    groupCode: chosenGroup,
                    lessonToMoveSlug: lessonSlug,
                    moveBeforeLessonSlug: updatedLessons[direction === 'forward' ? index + 2 : index - 1]?.slug || null,
                },
            },
        }).then(() => {
            getLessons();
        });
    };

    if (loading) return <Loading />;
    if (error) return <div>Error loading lessons</div>;
    return (
        <div className="lessons light">
            <div className="content">
                <Header light={true} />
                <Hero title="Nauka" />
                <div className="lessons__container container">
                    <h2 className="lessons__title">Lista lekcji</h2>
                    <div className="lessons__items">
                        {updatedLessons.map((lesson, index) => {
                            const handleMoveBack = index === 0 ? undefined : () => handleOrderChange('back', index);
                            const handleMoveForward = index === updatedLessons.length - 1 ? undefined : () => handleOrderChange('forward', index);
                            console.log(index, updatedLessons.length - 1, index === updatedLessons.length - 1, handleMoveForward);
                            return <LessonItem isInstructor={isInstructor} key={lesson.title} title={lesson.title} url={`/${chosenGroup}/lesson/${lesson.slug}`} status={lesson.status} handleMoveBack={handleMoveBack} handleMoveForward={handleMoveForward} />;
                        })}
                        {isInstructor && <LessonItem title="Dodaj lekcję" add url="/add-lesson" />}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
