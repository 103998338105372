import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ITest, Trump, getAnswersArray, orderCardsNeatly, santizeId, suitsEnum, testSequencesToTestWithEmoji } from '../helpers.ts';
import { fixCards } from '../helpers/fixCardsValues.ts';

// interface Bid extends UserBid {
//   string: string;
// }

export type Tag = {
    text: string;
    id: string;
    isBadgeActive: boolean;
    percent: number;
    all: number;
};

export interface BiddingState {
    value: number;
    southCards: string[];
    currentDepth: number;
    testDepth: number;
    currentAnswers: string[];
    currentHalfAnswers: string[];
    userAnswers: UserAnswer[];
    userBid: UserBid | null;
    testId: number;
    allTests: ITest[];
    filteredTests: ITest[];
    drawnTest: ITest;
    isAnsweredCorrectly: 0 | 0.5 | 1 | null;
    wholeSequence: UserBid[];
    allTestsDrawerOpen: boolean;
    yourResultsDrawerOpen: boolean;
    possibleTags: Tag[];
    filters: string[];
    points: number;
    efficiency: number;
}

const initialState: BiddingState = {
    value: 0,
    southCards: null,
    currentDepth: 0,
    testDepth: 0,
    currentAnswers: [],
    currentHalfAnswers: [],
    userBid: null,
    testId: null,
    allTests: [],
    filteredTests: [],
    drawnTest: null,
    isAnsweredCorrectly: null,
    wholeSequence: [],
    allTestsDrawerOpen: false,
    yourResultsDrawerOpen: false,
    possibleTags: [],
    filters: [],
    points: 0,
    efficiency: 0,
    userAnswers: [],
};

type UserBid = {
    bidNumber: number;
    bidTrump: Trump;
};
type UserAnswer = {
    string: string;
    enum: suitsEnum;
    number: string;
    result: null | 0 | 0.5 | 1;
};

export const biddingSlice = createSlice({
    name: 'bidding',
    initialState,
    reducers: {
        setSouthCards: (state, action: PayloadAction<string[]>) => {
            state.southCards = action.payload;
        },
        openAllTestsDrawer: (state) => {
            // console.log('iopen')
            state.allTestsDrawerOpen = true;
        },
        closeAllTestsDrawer: (state) => {
            state.allTestsDrawerOpen = false;
        },
        openYourResultsDrawer: (state) => {
            state.yourResultsDrawerOpen = true;
        },
        closeYourResultsDrawer: (state) => {
            state.yourResultsDrawerOpen = false;
        },
        setCurrentHalfAnswers: (state, action: PayloadAction<string[]>) => {
            state.currentHalfAnswers = action.payload;
        },
        setEfficiency: (state, action: PayloadAction<number>) => {
            state.efficiency = action.payload || 0;
        },
        setCurrentAnswers: (state, action: PayloadAction<string[]>) => {
            state.currentAnswers = action.payload;
        },
        setCurrentDepth: (state, action: PayloadAction<number>) => {
            state.currentDepth = action.payload;
        },
        updatePossibleTagsBadge: (
            state,
            action: PayloadAction<{
                tag: string;
                isBadgeActive: boolean;
                percent: number;
                all: number;
            }>
        ) => {
            const tags = [...state.possibleTags];
            const index = tags.findIndex((t) => {
                return t.id === action.payload.tag;
            });
            tags[index].isBadgeActive = action.payload.isBadgeActive;
            tags[index].percent = action.payload.percent;
            tags[index].all = action.payload.all;
            state.possibleTags = tags;
        },
        setUserBid: (state, action: PayloadAction<UserBid | null>) => {
            state.userBid = action.payload;
        },
        setTestId: (state, action: PayloadAction<number>) => {
            state.testId = action.payload;
        },
        setPoints: (state, action: PayloadAction<number>) => {
            state.points = action.payload;
        },
        setAllTests: (state, action: PayloadAction<ITest[]>) => {
            //   console.log("setAllTests redusx", action.payload);
            state.allTests = action.payload;
            const filteredTests = action.payload.filter((t) => state.filters.includes(santizeId(t?.tag))).filter((t) => state.filters.includes(santizeId(`level-${t?.level}`)));
            state.filteredTests = filteredTests;
        },
        setPossibleTags: (state, action: PayloadAction<ITest[]>) => {
            //   console.log("setAllTests redusx", action.payload);
            state.possibleTags = action.payload;
        },
        setFilters: (state, action: PayloadAction<ITest[]>) => {
            //   console.log("filters", action.payload);
            state.filters = action.payload;
            const filteredTests = state.allTests.filter((t) => action.payload.includes(santizeId(t?.tag))).filter((t) => action.payload.includes(santizeId(`level-${t?.level}`)));
            state.filteredTests = filteredTests;
        },
        incrementDepth: (state, action) => {
            if (state.currentDepth === state.testDepth) {
                state.currentDepth += 1;
                return;
            }
            // console.log(`answer${state.currentDepth+1}`, state.drawnTest)
            const answersArray = getAnswersArray(state.drawnTest[`answer${state.currentDepth + 1}`]);
            state.currentAnswers = answersArray;
            const halfAnswersArray = getAnswersArray(state.drawnTest[`halfAnswers${state.currentDepth + 1}`]);
            state.currentHalfAnswers = halfAnswersArray;
            state.currentDepth += 1;
            const seqIndex = [...state.wholeSequence].findIndex((item) => {
                return item?.string === '?';
            });
            const seq = [...state.wholeSequence];
            seq.splice(seqIndex, 1, action.payload);
            state.wholeSequence = seq;
        },
        setTest: (state, action) => {
            // console.log(action.payload)
            const drawnTest = state.allTests.filter((test) => test.id === action.payload)[0];

            state.drawnTest = drawnTest;
            const { spades, hearts, clubs, diamonds } = fixCards(drawnTest.spades, drawnTest.hearts, drawnTest.clubs, drawnTest.diamonds);

            const cardsOrdered = orderCardsNeatly(spades, hearts, clubs, diamonds);

            const controlSum = cardsOrdered?.length;
            //   if (controlSum !== 13) {
            // 	resetAll();
            // 	console.log(
            // 	  "prevent error in cards, cards quantity does not equal 13, but: ",
            // 	  controlSum,
            // 	  ", test ID:",
            // 	  randomTest.id
            // 	);
            // 	drawRandomTest();
            // 	return;
            //   }
            // dispatch(setSouthCards(cardsOrdered));
            //   console.log("setSouthCards by redux");
            state.southCards = cardsOrdered;
            //answers logic:
            //   if (!randomTest?.answer) {
            // 	setIsLoading(false);
            // 	return;
            //   }

            const answersArray = getAnswersArray(drawnTest?.answer);

            //   if (answersArray.filter((item) => item == null)?.length > 0) {
            // 	resetAll();
            // 	drawRandomTest();
            // 	console.log("prevent error in answers", randomTest.id);
            // 	return;
            //   }
            state.currentAnswers = answersArray;

            //   if (!randomTest?.halfAnswers) {
            //     setIsLoading(false);
            //     return;
            //   }
            //TODO: DRY this logic
            const halfAnswersArray = getAnswersArray(drawnTest?.halfAnswers);

            //   if (halfAnswersArray.filter((item) => item == null)?.length > 0) {
            //     resetAll();
            //     drawRandomTest();
            //     console.log("prevent error in halfAnswers", randomTest.id);
            //     return;
            //   }
            state.currentHalfAnswers = halfAnswersArray;

            //getPC logic:
            //   if (
            //     !drawnTest?.spades ||
            //     !drawnTest?.diamonds ||
            //     !drawnTest?.clubs ||
            //     !drawnTest?.hearts
            //   ) {
            //     state.southCardsPC = null;
            //   } else {
            //     const PC =
            //       getPointCount(drawnTest?.spades, isSingle(drawnTest?.spades)) +
            //       getPointCount(drawnTest?.hearts, isSingle(drawnTest?.hearts)) +
            //       getPointCount(drawnTest?.diamonds, isSingle(drawnTest?.diamonds)) +
            //       getPointCount(drawnTest?.clubs, isSingle(drawnTest?.clubs));
            //     state.southCardsPC = PC;
            //   }

            const { testSequence, depth } = testSequencesToTestWithEmoji(drawnTest);
            state.wholeSequence = testSequence;
            //       console.log(wholeSequence);
            // console.log("testArray", testWithEmoji.length)
            state.testDepth = depth;
            state.currentDepth = 0;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setSouthCards, setCurrentAnswers, setCurrentDepth, setCurrentHalfAnswers, setTestId, setAllTests, setUserBid, incrementDepth, openAllTestsDrawer, openYourResultsDrawer, closeAllTestsDrawer, closeYourResultsDrawer, setPossibleTags, setFilters, setPoints, setEfficiency, updatePossibleTagsBadge, setTest } = biddingSlice.actions;

export default biddingSlice.reducer;
