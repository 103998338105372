import { IBidReference } from '@/pages/Backoffice/components/BackofficeTestEditMode.js';
import React, { useState } from 'react';
import Bin from '../../assets/Bin.tsx';
import { Clubs } from '../../assets/Clubs.tsx';
import { Diamonds } from '../../assets/Diamonds.tsx';
import { Hearts } from '../../assets/Hearts.tsx';
import { Spades } from '../../assets/Spades.tsx';
import { BiddingCardColorView, BiddingCardValueView, BiddingDeclarationView, BiddingView } from '../../graphql/types.gen.ts';
import { bidValueToNumber, valueToBiddingCardValue } from '../../helpers.ts';
import './BiddingUI.scss';
import { useBidLimitations } from './hooks/useBidLimitations.ts';
interface BidUIProps {
    handleUserBids: (b: BiddingView | null) => void;
    recentBids?: BiddingView[];
    isFocused?: IBidReference | null;
    backofficeMode?: boolean;
    setIsFocused: (value: IBidReference | null) => void;
    onSubmit: () => void;
}

export const trumpNameToIcon = (trump: BiddingCardColorView) => {
    switch (trump) {
        case BiddingCardColorView.Clubs:
            return <Clubs />;
        case BiddingCardColorView.Diamonds:
            return <Diamonds />;
        case BiddingCardColorView.Hearts:
            return <Hearts />;
        case BiddingCardColorView.Spades:
            return <Spades />;
        case BiddingCardColorView.NoTrumps:
            return 'BA';
    }
};

export const TRUMPS = [BiddingCardColorView.Clubs, BiddingCardColorView.Diamonds, BiddingCardColorView.Hearts, BiddingCardColorView.Spades, BiddingCardColorView.NoTrumps];
export const NUMBERS = [BiddingCardValueView.One, BiddingCardValueView.Two, BiddingCardValueView.Three, BiddingCardValueView.Four, BiddingCardValueView.Five, BiddingCardValueView.Six, BiddingCardValueView.Seven];

export const BiddingUI = (props: BidUIProps) => {
    const { isFocused = true, backofficeMode = false, setIsFocused, onSubmit } = props;
    const wholeSequence = props?.recentBids || [];
    const [localBidNumber, setLocalBidNumber] = useState<number | null>(null);
    const [shouldAlert, setShouldAlert] = useState(false);
    const { canUseKtr, canUseRktr, lowestPossibleNumber, lowestPossibleTrump } = useBidLimitations(wholeSequence, backofficeMode);
    console.log('wholeSequence', wholeSequence);
    return (
        <div className="biddingUI">
            {!backofficeMode && <h2 className="biddingUI__title">Co powinien zalicytować gracz S?</h2>}
            <div className="biddingUI-row row-numbers">
                <button
                    className={`biddingUI_button biddingUI_button-alert ${shouldAlert ? 'biddingUI_button-alert--on' : ''}`}
                    disabled={!isFocused}
                    onClick={() => {
                        setShouldAlert((prev) => !prev);
                    }}
                >
                    ALERT
                </button>
            </div>
            <div className="biddingUI-row row-numbers">
                {NUMBERS.map((number) => {
                    return (
                        <button
                            key={bidValueToNumber(number)}
                            disabled={!isFocused || bidValueToNumber(number) < lowestPossibleNumber}
                            className={`biddingUI_button ${bidValueToNumber(number) === localBidNumber ? 'chosen' : ''}`}
                            onClick={() => {
                                setLocalBidNumber(bidValueToNumber(number));
                            }}
                        >
                            {bidValueToNumber(number)}
                        </button>
                    );
                })}
            </div>
            <div className="biddingUI-row suits">
                {TRUMPS.map((trump, index) => {
                    return (
                        <button
                            key={trump}
                            disabled={!isFocused || !localBidNumber || (index < lowestPossibleTrump && localBidNumber === lowestPossibleNumber)}
                            className={`biddingUI_button`}
                            onClick={() => {
                                if (!localBidNumber) return;
                                const bid = {
                                    declaration: BiddingDeclarationView.Bid,
                                    bidCard: {
                                        color: trump,
                                        value: valueToBiddingCardValue(localBidNumber),
                                    },
                                    shouldAlert: shouldAlert,
                                };
                                props.handleUserBids(bid);
                                setLocalBidNumber(null);
                                setShouldAlert(false);
                            }}
                        >
                            {trumpNameToIcon(trump)}
                        </button>
                    );
                })}
            </div>
            <div className="biddingUI-row">
                <button
                    className="biddingUI_button biddingUI_button-ktr"
                    disabled={!isFocused || !canUseKtr}
                    onClick={() => {
                        const bid = {
                            declaration: BiddingDeclarationView.Double,
                            bidCard: null,
                            shouldAlert: shouldAlert,
                        };
                        props.handleUserBids(bid);
                        setShouldAlert(false);
                        setLocalBidNumber(null);
                    }}
                >
                    X
                </button>
                <button
                    className="biddingUI_button biddingUI_button-rktr"
                    disabled={!isFocused || !canUseRktr}
                    onClick={() => {
                        const bid = {
                            declaration: BiddingDeclarationView.Redouble,
                            bidCard: null,
                            shouldAlert: shouldAlert,
                        };
                        props.handleUserBids(bid);
                        setShouldAlert(false);
                        setLocalBidNumber(null);
                    }}
                >
                    XX
                </button>
                <button
                    className="biddingUI_button biddingUI_button-pas"
                    disabled={!isFocused}
                    onClick={() => {
                        const bid = {
                            declaration: BiddingDeclarationView.Pass,
                            bidCard: null,
                            shouldAlert: shouldAlert,
                        };
                        props.handleUserBids(bid);
                        setShouldAlert(false);
                        setLocalBidNumber(null);
                    }}
                >
                    PAS
                </button>
                <div className="biddingUI-row">
                    {backofficeMode && (
                        <button
                            className="biddingUI_button biddingUI_button-undo"
                            disabled={!isFocused}
                            onClick={() => {
                                props.handleUserBids(null);
                                setShouldAlert(false);
                                setLocalBidNumber(null);
                            }}
                        >
                            <div className="biddingUI_button__icon">
                                <Bin />
                            </div>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
