import React from 'react'
import { SequenceItem } from '../../../../components/Sequence/SequenceItem.tsx'
import { BiddingView, ExamVulnerabilityView } from '../../../../graphql/types.gen'
import { IBackofficeSeqItem } from '../../helpers/useGetBackofficeBidsArray.ts'
import { AddItem } from '../AddItem/AddItem.tsx'
import { IBidReference } from '../BackofficeTestEditMode'
import './BackofficeBidSequence.scss'
interface IBackofficeBidSequence {
    arr: IBackofficeSeqItem[]
    vulnerability: ExamVulnerabilityView
    bidReference?: IBidReference | null
    setBidReference?: (value: IBidReference) => void
    editMode?: boolean
}

export const BackofficeBidSequence = (props: IBackofficeBidSequence) => {
    const { arr, vulnerability, setBidReference, bidReference, editMode = false } = props

    const indexToSeat = (index: number) => {
        return ['W', 'N', 'E'][index]
    }
    const focusBidSlot = (index: number, innerIndex: number, type: 'bids' | 'answers' | 'halfAnswers') => {
        setBidReference && setBidReference({ index, innerIndex, type })
    }
    const modArr = editMode
        ? [
              ...arr.map((obj: IBackofficeSeqItem) => {
                  return { bids: obj?.bids, answers: obj.answers.length >= 2 ? [...obj.answers] : [...obj.answers, null], halfAnswers: obj.halfAnswers.length >= 2 ? [...obj.halfAnswers] : [...obj.halfAnswers, null] }
              }),
              { bids: [null, null, null], answers: [null], halfAnswers: [null] },
          ]
        : arr

    // console.log(modArr)
    // const wrapperRef = useRef<HTMLDivElement>(null)

    // useEffect(() => {
    //     const handleClickOutside = (event: MouseEvent) => {
    //         if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
    //             console.log('event.target', event.target)
    //             setBidReference && setBidReference(null)
    //         }
    //     }

    //     document.addEventListener('mousedown', handleClickOutside)

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside)
    //     }
    // }, [])

    return (
        <div className={`test-sequence-grid ${vulnerability}`}>
            <p className="test-sequence-grid__seat">W</p>
            <p className="test-sequence-grid__seat">N</p>
            <p className="test-sequence-grid__seat">E</p>
            <p className="test-sequence-grid__seat">odp S</p>
            <p className="test-sequence-grid__seat"></p>
            {modArr &&
                modArr.map((obj, index) => {
                    return (
                        <React.Fragment key={index}>
                            {obj?.bids &&
                                obj?.bids.map((item: BiddingView, innerIndex: number) => {
                                    const isFocused = bidReference?.index === index && bidReference?.innerIndex === innerIndex && bidReference?.type === 'bids'
                                    return (
                                        <div key={`depth1-${index}-${innerIndex}}`} className={`test-sequence-grid__item-wrapper bid-${indexToSeat(innerIndex)}`}>
                                            {item && (
                                                <SequenceItem
                                                    handleClick={() => {
                                                        focusBidSlot(index, innerIndex, 'bids')
                                                    }}
                                                    classes={`test-sequence-grid__item test-sequence-grid__item--edit ${index} ${isFocused ? 'focused' : ''}`}
                                                    item={item}
                                                />
                                            )}
                                            {editMode && !item && (
                                                <AddItem
                                                    handleClick={() => {
                                                        focusBidSlot(index, innerIndex, 'bids')
                                                    }}
                                                    classes={`${isFocused ? ' focused' : ''}`}
                                                />
                                            )}
                                        </div>
                                    )
                                })}
                            {!!obj.answers.length && (
                                <div className="test-sequence-grid__answers test-sequence-grid__item-wrapper bid-S">
                                    {obj.answers.map((item: BiddingView, innerIndex: number) => {
                                        const isFocused = bidReference?.index === index && bidReference?.innerIndex === innerIndex && bidReference?.type === 'answers'
                                        return (
                                            <React.Fragment key={`depth2-${index}-${innerIndex}}`}>
                                                {innerIndex !== 0 && <p className="test-sequence-grid__word">LUB:</p>}
                                                <div className={`test-sequence-grid__end-wrapper`}>
                                                    {item && (
                                                        <SequenceItem
                                                            handleClick={() => {
                                                                focusBidSlot(index, innerIndex, 'answers')
                                                            }}
                                                            classes={`test-sequence-grid__item test-sequence-grid__item--edit ${isFocused ? 'focused' : ''}`}
                                                            item={item}
                                                        />
                                                    )}
                                                    {editMode && !item && (
                                                        <AddItem
                                                            handleClick={() => {
                                                                focusBidSlot(index, innerIndex, 'answers')
                                                            }}
                                                            classes={`${isFocused ? ' focused' : ''}`}
                                                        />
                                                    )}
                                                    {innerIndex !== 0 && <p className="test-sequence-grid__word">KONIEC</p>}
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            )}
                            {obj.halfAnswers.length ? (
                                <div className="test-sequence-grid__halfAnswers test-sequence-grid__item-wrapper bid-S">
                                    <p className="test-sequence-grid__word">PRAWIE DOBRZE:</p>
                                    {obj.halfAnswers.map((item: BiddingView, innerIndex: number) => {
                                        const isFocused = bidReference?.index === index && bidReference?.innerIndex === innerIndex && bidReference?.type === 'halfAnswers'
                                        return (
                                            <React.Fragment key={`depth3-${index}-${innerIndex}}`}>
                                                {innerIndex !== 0 && <p className="test-sequence-grid__word">LUB:</p>}
                                                <div className={`test-sequence-grid__end-wrapper`}>
                                                    {item && (
                                                        <SequenceItem
                                                            handleClick={() => {
                                                                focusBidSlot(index, innerIndex, 'halfAnswers')
                                                            }}
                                                            classes={`test-sequence-grid__item test-sequence-grid__item--edit  ${isFocused ? 'focused' : ''}`}
                                                            item={item}
                                                        />
                                                    )}
                                                    {editMode && !item && (
                                                        <AddItem
                                                            handleClick={() => {
                                                                focusBidSlot(index, innerIndex, 'halfAnswers')
                                                            }}
                                                            classes={`${isFocused ? ' focused' : ''}`}
                                                        />
                                                    )}
                                                    <p className="test-sequence-grid__word">KONIEC</p>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </React.Fragment>
                    )
                })}
        </div>
    )
}
