import { createTheme } from '@mui/material'

export const PRIMARY_MAIN = '#1F271B'
export const PRIMARY_LIGHT = '#F5E9E2'

export const THEME = createTheme({
    palette: {
        primary: {
            light: PRIMARY_LIGHT,
            main: PRIMARY_MAIN,
            contrastText: '#F5E9E2',
        },
        secondary: {
            light: '#ffffff',
            main: '#fffff',
            contrastText: '#F5E9E2',
        },
    },
    typography: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
    },
    shape: {
        borderRadius: 40,
    },
})
