import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../..//components/Button/Button.tsx';
import { Header } from '../../../components/Header/Header.tsx';
import { Loading } from '../../../components/Loading/Loading.tsx';
import { ExamsSummaryOrderBy, ExamsSummaryOrderDirection } from '../../../graphql/types.gen.ts';
import { RootState } from '../../../redux/store.ts';
import { setChosenGroup, setToken, setUser } from '../../../redux/userSlice.ts';
import '../FormPage.scss';
import { useMeLazyQuery, useSignInMutation } from './loginPage.gen.ts';
import { loginSchema } from './loginSchema.ts';

const LoginPage = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [signInMutation, { loading, error }] = useSignInMutation();
    const user = useSelector((state: RootState) => state.user);
    const [getMe, { data }] = useMeLazyQuery();

    const handleSubmit = async (values: any) => {
        await signInMutation({
            variables: {
                input: values,
            },
        }).then((res) => {
            console.log(res);
            const accessToken = res.data?.signIn.result?.accessToken;
            if (!accessToken) {
                dispatch(setToken(null));
                return;
            }
            dispatch(setToken(accessToken));
            getMe({
                variables: {
                    input: {
                        ordering: {
                            orderBy: ExamsSummaryOrderBy.Tag,
                            orderDirection: ExamsSummaryOrderDirection.Desc,
                        },
                    },
                },
            }).then((res2) => {
                console.log(res2);
                dispatch(setUser({ username: res2?.data?.me.username, email: res2?.data.me.email }));

                if (res2?.data?.me.groups.length === 1) {
                    dispatch(setChosenGroup(res2?.data?.me.groups[0].code));
                } else if (!user.chosenGroup && res2?.data?.me.groups.length === 0) {
                    dispatch(setChosenGroup('general'));
                }
                navigate('/game');
            });
        });
    };

    const handleUnregistered = () => {
        sessionStorage.setItem('unregisteredUser', JSON.stringify(true));
        dispatch(setChosenGroup('general'));
        navigate('/game');
    };

    return (
        <div className="formPage">
            <Header formHeader={true} />
            <p className="formPage__heading">
                <span className="formPage__heading--licytapka">Licytapka</span> to Twoja partnerka w nauce licytacji brydżowych
            </p>
            <Formik
                initialValues={{
                    login: props.nick || '',
                    password: props.password || '',
                }}
                validationSchema={loginSchema}
                onSubmit={handleSubmit}
            >
                {(formik) => {
                    const { errors, touched, isValid, dirty } = formik;

                    return (
                        <div className="formPage__formContainer">
                            <div className="formPage__col formPage__col--left">
                                <h2 className="formPage__title">Zaloguj się</h2>
                                <Form>
                                    <div className="formPage__formGroup form-row">
                                        <Field type="login" placeholder="Email lub nick*" name="login" id="login" className={`formPage__formControl ${errors.login && touched.login ? 'input-error' : ''}`} />
                                        <ErrorMessage name="login" component="span" className="error" />
                                    </div>
                                    <div className="formPage__formGroup form-row">
                                        <Field placeholder="Hasło*" type="password" name="password" id="password" className={`formPage__formControl ${errors.password && touched.password ? 'input-error' : ''}`} />
                                        <ErrorMessage name="password" component="span" className="error" />
                                    </div>
                                    <button type="submit" className={`button formPage__button${!(dirty && isValid) ? ' disabled' : ''}`} disabled={!(dirty && isValid)}>
                                        Zaloguj się
                                    </button>
                                </Form>

                                {error && <p className="formPage__error">{error.message}</p>}
                            </div>
                            <div className="formPage__col formPage__col--right">
                                <h2 className="formPage__title">Nie masz konta?</h2>
                                <Button customClass="formPage__button" variant="ghost-white" onClick={() => navigate('/signup')} text="Załóż konto" />
                                <p className="formPage__noAccount">
                                    Nie chcesz korzystać z fajnych funkcjonalności jak Statystyki i Grupy?{' '}
                                    <Button
                                        customClass="formPage__link"
                                        variant="text"
                                        onClick={() => {
                                            handleUnregistered();
                                        }}
                                    >
                                        Korzystaj z Licytapki bez zakładania konta.
                                    </Button>
                                </p>
                            </div>
                        </div>
                    );
                }}
            </Formik>
            {loading && <Loading />}
        </div>
    );
};
export default LoginPage;
