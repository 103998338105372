import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { RootState } from '../../../redux/store.ts';
import { BiddingView } from '../../graphql/types.gen.ts';
import { compareTwoBids } from '../../helpers.ts';
import { IResult } from './Game';
import { useExamFromGroupLazyQuery, useExamResultsSaveMutation, usePublicGroupsLazyQuery } from './game.gen.ts';

export const useGame = (id?: string, onSubmit?: () => void) => {
    const navigate = useNavigate();
    const [userBids, setUserBids] = useState<BiddingView[]>([]);
    const [lastUserBid, setLastUserBid] = useState<BiddingView>({});
    const [bestAnswers, setBestAnswers] = useState<BiddingView[]>([]);
    const [result, setResult] = useState<IResult>(null);
    const [recentBids, setRecentBids] = useState<BiddingView[]>([]);
    const { token } = useSelector((state: RootState) => state.user);

    const { chosenGroup, isInitLoading } = useSelector((state: RootState) => state.user);

    const dispatch = useDispatch();
    const handleRecentBids = (bids: BiddingView[]) => {
        setRecentBids(bids);
    };
    const [examResultsSaveMutation] = useExamResultsSaveMutation();
    const [getExam, { data: testData, loading: testLoading, error: testError }] = useExamFromGroupLazyQuery();
    // console.log(testData)
    const [getPublicExam, { data: publicTestData, loading: publicTestLoading, error: publicTestError }] = usePublicGroupsLazyQuery();
    const fetchExam = chosenGroup && chosenGroup !== 'general' ? getExam : getPublicExam;
    const data = chosenGroup && chosenGroup !== 'general' ? testData : publicTestData;
    const loading = chosenGroup && chosenGroup !== 'general' ? testLoading : publicTestLoading;
    const error = chosenGroup && chosenGroup !== 'general' ? testError : publicTestError;
    //   const { data, loading, error } = usePublicGroupsItemsQuery({
    //       variables:{
    //         "input": {
    //           "filters": {
    //             "difficultyLevel": [],
    //             "tags": []
    //           },
    //           "pagination": {
    //             "limit": 10,
    //             "offset": 0
    //           }
    //         }
    //       },
    //     });
    console.log(chosenGroup, data, publicTestData);

    // useEffect(() => {
    //     if (error && !loading) {
    //         getPublicExam({
    //             fetchPolicy: 'no-cache',
    //             variables: {
    //                 input: {
    //                     random: {
    //                         difficultyLevel: [],
    //                         tags: [],
    //                     },
    //                 },
    //             },
    //         });
    //     }
    // }, [error, loading]);

    useEffect(() => {
        if (!chosenGroup) {
            //
        }
        // console.log('draw test useEffect', chosenGroup)
        id ? drawTestById(id) : drawTest();
        console.log('draw test useEffect', chosenGroup);
    }, [chosenGroup, id]);

    const reset = () => {
        console.log('reset');
        setUserBids([]);
        setLastUserBid({});
        setBestAnswers([]);
        setResult(null);
    };

    // const { data, loading, error } = useExamsFromGroupQuery({
    //     variables: {
    //         code: chosenGroup,
    //         input: {
    //             filters: {
    //                 difficultyLevel: [],
    //                 tags: []
    //             },
    //             pagination: {
    //                 limit: 50,
    //                 offset: 0
    //             }
    //         },
    //     }
    // });

    const randomTest = chosenGroup && chosenGroup !== 'general' ? testData?.group?.exam : publicTestData?.publicGroups[0]?.exam;
    console.log(randomTest);
    useEffect(() => {
        if (!userBids.length) return;
        setLastUserBid(userBids.slice(-1)[0]);
        console.log(userBids);
    }, [userBids]);

    const handleResult = (r: IResult) => {
        setResult(r);
    };

    const drawTest = () => {
        console.log('draaaaaaw');
        reset();
        fetchExam({
            fetchPolicy: 'no-cache',
            variables: {
                code: chosenGroup || 'general',
                input: {
                    random: {
                        difficultyLevel: [],
                        tags: [],
                    },
                },
            },
        });
    };
    const repeatTest = () => {
        reset();
        fetchExam({
            fetchPolicy: 'no-cache',
            variables: {
                code: chosenGroup || 'general',
                input: {
                    id: randomTest?.id,
                },
            },
        });
    };
    const drawTestById = (id: string) => {
        reset();
        fetchExam({
            fetchPolicy: 'no-cache',
            variables: {
                code: chosenGroup || 'general',
                input: {
                    id: id,
                },
            },
        });
    };
    const handleNextTest = () => {
        reset();
        if (!randomTest?.id) return;
        fetchExam({
            fetchPolicy: 'no-cache',
            variables: {
                code: chosenGroup || 'general',
                input: {
                    next: {
                        filters: {
                            difficultyLevel: [],
                            tags: [],
                        },
                        startFromId: randomTest?.id,
                    },
                },
            },
        });
    };
    const sendResultToBackend = (b) => {
        examResultsSaveMutation({
            variables: {
                input: {
                    examId: randomTest.id,
                    answers: [...userBids, { ...b }],
                    groupCode: chosenGroup,
                    revisionId: randomTest.revisionId,
                },
            },
        }).then(() => {
            onSubmit && onSubmit();
        });
    };

    const southCards = useMemo(() => {
        return randomTest?.definition.cards;
    }, [randomTest?.definition.cards]);
    const handleUserBids = (b: BiddingView) => {
        if (compareTwoBids(b, randomTest.definition.answers[userBids.length].halfAnswers[0]) || compareTwoBids(b, randomTest.definition.answers[userBids.length].halfAnswers[1])) {
            setResult('HALF');
            setBestAnswers([randomTest.definition.answers[userBids.length].matchingAnswer]);
            token && sendResultToBackend(b);

            //TODO: replace this when backend sends array of matching answers
            // } else if (compareTwoBids(b, randomTest.definition.answers[userBids.length].matchingAnswer[0]) || compareTwoBids(b, randomTest.definition.answers[userBids.length].matchingAnswer[1])) {
        } else if (compareTwoBids(b, randomTest.definition.answers[userBids.length].matchingAnswer) || compareTwoBids(b, randomTest.definition.answers[userBids.length].matchingAnswer[1])) {
            if (userBids.length === randomTest.definition.answers.length - 1) {
                setResult('FULL');
                token && sendResultToBackend(b);
            }
        } else {
            setBestAnswers([randomTest.definition.answers[userBids.length].matchingAnswer]);
            setResult('BAD');
            token && sendResultToBackend(b);
        }
        setUserBids((prev) => [...prev, { ...b }]);
    };

    // useEffect(() => {
    //     if (testLoading || !randomTest) return
    //     if (result === 'HALF' || result === 'BAD') {
    //         let goodAnswers: BiddingView[] = []
    //         const artificialId =
    //             '$' +
    //             userBids
    //                 .slice(0, -1)
    //                 .map((b) => `.${b.declaration}_${b.bidCard?.value}_${b.bidCard?.color}`)
    //                 .join('')

    //         const lastGoodAnswer = randomTest.definition.answers.filter((item) => {
    //             return item.artificialId === artificialId && item.correctness === 'FULL'
    //         })[0]

    //         if (!lastGoodAnswer) {
    //             goodAnswers = randomTest.definition.answers
    //                 .filter((item) => {
    //                     return [...item.artificialId.matchAll(/\./g)].length === 1 && item.correctness === 'FULL'
    //                 })
    //                 .map((item) => item.matchingAnswer)
    //         } else {
    //             if (!lastGoodAnswer) return
    //             const subAnswerIds = lastGoodAnswer?.subAnswerIds
    //             if (!subAnswerIds) return
    //             subAnswerIds.forEach((id) => {
    //                 const item = randomTest.definition.answers.filter((item) => item.artificialId === id && item.correctness === 'FULL')
    //                 if (item.length) goodAnswers.push(item[0].matchingAnswer)
    //             })
    //         }

    //         setBestAnswers(goodAnswers)
    //     }
    // }, [result, testLoading])

    return {
        testError: error,
        isInitLoading,
        randomTest,
        testLoading: loading,
        drawTestById,
        chosenGroup,
        handleResult,
        userBids,
        handleRecentBids,
        recentBids,
        drawTest,
        handleUserBids,
        lastUserBid,
        bestAnswers,
        handleNextTest,
        repeatTest,
        southCards,
        result,
    };
};
