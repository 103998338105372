import { Add } from '@mui/icons-material'
import React from 'react'
import './AddItem.scss'

interface IAddItemProps {
    handleClick?: () => void
    classes: string
}
export const AddItem = (props: IAddItemProps) => {
    const { handleClick, classes } = props
    return (
        <button
            onClick={() => {
                !!handleClick && handleClick()
            }}
            className={`addItem${classes}`}
        >
            <div className="addItem__wrapper">
                <Add />
            </div>
        </button>
    )
}
