import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Header } from '../../components/Header/Header.tsx'

export const CheckEmail = () => {
    const navigate = useNavigate()
    const [redirectTimer, setRedirectTimer] = useState(10)

    useEffect(() => {
        const timer = setInterval(() => {
            setRedirectTimer((prevTimer) => prevTimer - 1)
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [])

    useEffect(() => {
        if (redirectTimer === 0) {
            navigate('/login')
        }
    }, [redirectTimer])

    return (
        <div className="formPage">
            <Header formHeader={true} />
            <div className="formPage__content">
                <h1 className="formPage__title">Sprawdź swoją skrzynkę mailową</h1>
                <p className="formPage__desc">Wysłaliśmy do Ciebie email z linkiem aktywacyjnym. Kliknij w link, aby aktywować konto.</p>
                <p className="formPage__desc formPage__desc--redirect">Przekierujemy Cię do logowania za {redirectTimer}s</p>
            </div>
        </div>
    )
}
