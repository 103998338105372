import { BiddingCardColorView, BiddingCardValueView, BiddingView, CardValueView } from './graphql/types.gen.ts';

export const enum suitsEnum {
    'SPADES' = 'SPADES',
    'HEARTS' = 'HEARTS',
    'DIAMONDS' = 'DIAMONDS',
    'CLUBS' = 'CLUBS',
}
export const enum suitsHierarchy {
    'CLUBS',
    'DIAMONDS',
    'HEARTS',
    'SPADES',
    'NO_TRUMPS',
}

export type Trump = suitsEnum | BiddingCardColorView;

export const higherCardsArray = ['JACK', 'QUEEN', 'KING', 'ACE'];

export const higherCardsMap = {
    JACK: { hierarchyValue: 11, pointCardsValue: 1 },
    QUEEN: { hierarchyValue: 12, pointCardsValue: 2 },
    KING: { hierarchyValue: 13, pointCardsValue: 3 },
    ACE: { hierarchyValue: 14, pointCardsValue: 4 },
};

export const coloredCards = (cards) => {
    const spades = cards.filter((card) => card.suit === suitsEnum.SPADES);
    const hearts = cards.filter((card) => card.suit === suitsEnum.HEARTS);
    const clubs = cards.filter((card) => card.suit === suitsEnum.CLUBS);
    const diamonds = cards.filter((card) => card.suit === suitsEnum.DIAMONDS);
    return { spades, hearts, clubs, diamonds };
};

export const checkIfBadgeActive = (right, all): boolean => {
    return right / all > 0.5 && all >= 5;
};

export const getAnswersArray = (ans: string) => {
    return ans.split(',').map((a) => {
        const ans = a.trim();
        return bidStringToBidEmoji(ans);
    });
};

export interface BidExplanation {
    number: number;
    trump: Trump;
    bidString: string;
    explanationString: string;
}

export const colorsLibrary = new Map();

colorsLibrary.set(suitsEnum.SPADES, '♠️');
colorsLibrary.set(suitsEnum.HEARTS, '♥️');
colorsLibrary.set(suitsEnum.DIAMONDS, '♦️');
colorsLibrary.set(suitsEnum.CLUBS, '♣️');

export const orderCardsNeatly = (s, h, c, d) => {
    if (!h.length) return [...s, ...d, ...c];
    if (!c.length) return [...h, ...s, ...d];
    return [...s, ...h, ...d, ...c];
};

export interface ITest {
    vulnerability(vulnerability: any): unknown;
    id: string;
    tag: string;
    sequence: string;
    spades: string;
    hearts: string;
    diamonds: string;
    clubs: string;
    answer: string;
    halfAnswers: string;
}

export const trumpsDictionary = {
    trefl: { emoji: '♣️', enum: suitsEnum.CLUBS },
    karo: { emoji: '♦️', enum: suitsEnum.DIAMONDS },
    kier: { emoji: '♥️', enum: suitsEnum.HEARTS },
    pik: { emoji: '♠️', enum: suitsEnum.SPADES },
    BA: { emoji: 'BA', enum: 'BA' },
    pas: { emoji: 'pas', enum: 'pas' },
    ktr: { emoji: 'ktr', enum: 'ktr' },
    rktr: { emoji: 'rktr', enum: 'rktr' },
};

export const specialBids = {
    pas: { value: 0, string: 'PAS' },
    ktr: { value: -1, string: 'X' },
    rktr: { value: -2, string: 'XX' },
    '?': { value: null, string: '?' },
};

export enum TestType {
    DRURY = 'DRURY',
    MAGISTER = 'MAGISTER',
    ODWROTKA = 'ODWROTKA',
    JEDNOSTRONNA = 'JEDNOSTRONNA',
    DWUSTRONNA = 'DWUSTRONNA',
    BLACKWOOD = 'BLACKWOOD',
}

export const bidStringToBidEmoji = (bid): { string: string; enum: suitsEnum; number: string } => {
    const trimmedBid = bid.trim();
    if (Object.keys(specialBids).includes(trimmedBid))
        return {
            string: specialBids[trimmedBid].string,
            enum: specialBids[trimmedBid].value,
            number: null,
        };
    const number = trimmedBid.charAt(0);
    const trump = trimmedBid.substring(1);
    const emoji = trumpsDictionary[trump]?.emoji;
    return emoji
        ? {
              string: `${number}${emoji}`,
              enum: trumpsDictionary[trump].enum,
              number: number,
          }
        : null;
};

export const emptyStats = { right: 0, all: 0 };

export const resetLocalStorage = () => {
    localStorage.setItem('good', JSON.stringify([]));
    localStorage.setItem('bad', JSON.stringify([]));
    localStorage.setItem('half', JSON.stringify([]));
    localStorage.setItem('points', JSON.stringify(0));
    localStorage.setItem('strefa-szlemowa', JSON.stringify(emptyStats));
    localStorage.setItem('magister', JSON.stringify(emptyStats));
    localStorage.setItem('rebidy', JSON.stringify(emptyStats));
    localStorage.setItem('odwrotka', JSON.stringify(emptyStats));
    localStorage.setItem('odpowiedzi', JSON.stringify(emptyStats));
    localStorage.setItem('dwustronna-w-obronie', JSON.stringify(emptyStats));
    localStorage.setItem('otwarcia', JSON.stringify(emptyStats));
    localStorage.setItem('dwustronna-po-interwencji', JSON.stringify(emptyStats));
    localStorage.setItem('drury', JSON.stringify(emptyStats));
    window.location.reload();
};

const setTagStats = (tag, result, isFinished) => {
    console.log(tag, result, isFinished);
    const tagId = santizeId(tag);
    const tagStorage = JSON.parse(localStorage.getItem(tagId)) || emptyStats;
    if (isFinished || result === 0 || result === 1) {
        switch (result) {
            case 0:
                tagStorage.all = tagStorage.all += 1;
                break;
            case 0.5:
                tagStorage.all = tagStorage.all += 1;
                break;
            case 1:
                tagStorage.all = tagStorage.all += 1;
                tagStorage.right = tagStorage.right += 1;
                break;
        }
        localStorage.setItem(tagId, JSON.stringify(tagStorage));
    }
    return { tagStorage, tagId };
};

const RANK_DETAILS = {
    jack: {
        text: 'Walet',
        desc: 'Lepszy Walet w garści niż Król na dachu!',
        img: 'jack-crown',
        points: 0,
    },
    queen: {
        text: 'Królowa',
        desc: 'Królowa, huh? Do twarzy Ci w tej koronie',
        img: 'queen-crown',
        points: 20,
    },
    king: {
        text: 'Król',
        desc: 'Król, wow! Czy to oznacza, że teraz możemy cię obarczyć podatkami?',
        img: 'king-crown',
        points: 50,
    },
    joker: {
        text: 'Joker',
        desc: 'Joker, blefiarz, mistrz, żartowniś! Jesteś na szczycie!',
        img: 'joker-crown',
        points: 100,
    },
};

export const getRankDetailsByPoints = (points: number) => {
    if (points >= RANK_DETAILS.joker.points) {
        return {
            ...RANK_DETAILS.joker,
            toNextRank: 0,
        };
    } else if (points >= RANK_DETAILS.king.points) {
        return {
            ...RANK_DETAILS.king,
            toNextRank: RANK_DETAILS.joker.points - points,
        };
    } else if (points >= RANK_DETAILS.queen.points) {
        return {
            ...RANK_DETAILS.queen,
            toNextRank: RANK_DETAILS.king.points - points,
        };
    } else {
        return {
            ...RANK_DETAILS.jack,
            toNextRank: RANK_DETAILS.queen.points - points,
        };
    }
};

export const updateStats = (id: string, result: 0 | 0.5 | 1, tag, isFinished, level) => {
    //   console.log(id, result, tag);
    const half = JSON.parse(localStorage.getItem('half')) || [];
    const good = JSON.parse(localStorage.getItem('good')) || [];
    const bad = JSON.parse(localStorage.getItem('bad')) || [];
    let points = parseInt(JSON.parse(localStorage.getItem('points'))) || 0;
    localStorage.setItem('lastTestDate', JSON.stringify(new Date().toJSON()));

    const removeFromOtherArrays = (result, id) => {
        const indexOfGood = good.indexOf(id);
        const indexOfHalf = half.indexOf(id);
        const indexOfBad = bad.indexOf(id);

        switch (result) {
            case 0:
                indexOfGood !== -1 && good.splice(indexOfGood, 1);
                indexOfHalf !== -1 && half.splice(indexOfHalf, 1);
                break;
            case 0.5:
                indexOfGood !== -1 && good.splice(indexOfGood, 1);
                indexOfBad !== -1 && half.splice(indexOfBad, 1);

                break;
            case 1:
                indexOfBad !== -1 && bad.splice(indexOfBad, 1);
                indexOfHalf !== -1 && half.splice(indexOfHalf, 1);
                break;
        }
    };

    removeFromOtherArrays(result, id);
    console.log(level);
    switch (result) {
        case 0:
            if (bad.indexOf(id) === -1) bad.push(id);
            level == 1 ? (points -= 10) : (points -= 4);
            break;
        case 0.5:
            if (half.indexOf(id) === -1) half.push(id);
            points += 1;
            break;
        case 1:
            points += parseInt(level);
            if (good.indexOf(id) === -1) good.push(id);
            break;
    }
    localStorage.setItem('half', JSON.stringify(half));
    localStorage.setItem('good', JSON.stringify(good));
    localStorage.setItem('bad', JSON.stringify(bad));
    localStorage.setItem('points', JSON.stringify(points));

    const { tagStorage, tagId } = setTagStats(tag, result, isFinished);
    const allLength = half.length + good.length + bad.length;
    const efficiency = Math.floor(((half.length + good.length) / allLength) * 100) || allLength;

    return { points, tagId, tagStorage, efficiency };
};

export const getBidByValue = (v) => {
    switch (v) {
        case 0:
            return ' PAS';
        case -1:
            return ' kontra';
        case -2:
            return ' rekontra';
    }
};

export const santizeId = (id: string) => {
    return id ? id.replace(/\W/g, '-').toLowerCase() : '';
};

export const VULNERABILITY = {
    NONE: 'WSZYSCY PRZED',
    ALL: 'WSZYSCY PO',
    'EW-RED': 'KORZYSTNE',
    'NS-RED': 'NIEKORZYSTNE',
};

export const testSequencesToTestWithEmoji = (drawnTest) => {
    let emptyItemsToAdd = 3 - drawnTest?.sequence.split(',').length;

    let wholeSequence = '';
    if (drawnTest?.sequence === '?') {
        emptyItemsToAdd = 3;
        if (!drawnTest?.sequence1) {
            wholeSequence = '';
        } else {
            wholeSequence = '?,' + [drawnTest?.sequence1, drawnTest?.sequence2, drawnTest?.sequence3, drawnTest?.sequence4].filter(Boolean).join(',?,');
        }
    } else {
        wholeSequence = [drawnTest?.sequence, drawnTest?.sequence1, drawnTest?.sequence2, drawnTest?.sequence3, drawnTest?.sequence4].filter(Boolean).join(',?,');
    }

    let testArray = [];
    //   console.log(wholeSequence);
    if (wholeSequence) {
        testArray = wholeSequence.split(',');
        testArray.push('?');
    } else {
        testArray.push('?');
    }

    const testWithEmoji = testArray.map(bidStringToBidEmoji);

    const testSequence = testWithEmoji ? [...testWithEmoji] : null;
    const depth = Math.floor((testWithEmoji.length - 1) / 4);
    return { testSequence, depth, emptyItemsToAdd };
};
export const getColorByType = (type: string): string => {
    switch (type) {
        case 'PAS':
            return 'pass-green';
        case 'X':
            return 'ktr-red';
        case 'XX':
            return 'rktr-blue';
        default:
            return '';
    }
};

export enum CardValues {
    'TWO' = '2',
    'THREE' = '3',
    'FOUR' = '4',
    'FIVE' = '5',
    'SIX' = '6',
    'SEVEN' = '7',
    'EIGHT' = '8',
    'NINE' = '9',
    'TEN' = '10',
    'JACK' = 'J',
    'QUEEN' = 'Q',
    'KING' = 'K',
    'ACE' = 'A',
}

export const numberValueToNumber = (n: BiddingCardValueView | CardValueView): number | string => {
    switch (n) {
        case BiddingCardValueView.One:
            return 1;
        case BiddingCardValueView.Two:
            return 2;
        case BiddingCardValueView.Three:
            return 3;
        case BiddingCardValueView.Four:
            return 4;
        case BiddingCardValueView.Five:
            return 5;
        case BiddingCardValueView.Six:
            return 6;
        case BiddingCardValueView.Seven:
            return 7;
        case CardValueView.Eight:
            return 8;
        case CardValueView.Nine:
            return 9;
        case CardValueView.Ten:
            return 10;
        case CardValueView.Jack:
            return 'J';
        case CardValueView.Queen:
            return 'Q';
        case CardValueView.King:
            return 'K';
        case CardValueView.Ace:
            return 'A';
    }
};
export const valueToBiddingCardValue = (number: number): BiddingCardValueView => {
    switch (number) {
        case 1:
            return BiddingCardValueView.One;
        case 2:
            return BiddingCardValueView.Two;
        case 3:
            return BiddingCardValueView.Three;
        case 4:
            return BiddingCardValueView.Four;
        case 5:
            return BiddingCardValueView.Five;
        case 6:
            return BiddingCardValueView.Six;
        case 7:
            return BiddingCardValueView.Seven;
        default:
            return null;
    }
};

export const compareTwoBids = (bid1: BiddingView, bid2: BiddingView) => {
    if (!bid1 || !bid2) return false;
    if (bid1.declaration === bid2.declaration && bid1?.bidCard?.color === bid2?.bidCard?.color && bid1?.bidCard?.value === bid2?.bidCard?.value && bid1.shouldAlert === bid2.shouldAlert) {
        return true;
    } else {
        return false;
    }
};

export const bidValueToNumber = (n: BiddingCardValueView | CardValueView): number => {
    switch (n) {
        case BiddingCardValueView.One:
            return 1;
        case BiddingCardValueView.Two:
            return 2;
        case BiddingCardValueView.Three:
            return 3;
        case BiddingCardValueView.Four:
            return 4;
        case BiddingCardValueView.Five:
            return 5;
        case BiddingCardValueView.Six:
            return 6;
        case BiddingCardValueView.Seven:
        default:
            return 7;
    }
};
