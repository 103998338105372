import * as Types from '../../../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CodesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CodesQuery = { __typename?: 'Query', me: { __typename?: 'MeView', groups: Array<{ __typename?: 'GroupView', code: string }> } };


export const CodesDocument = gql`
    query Codes {
  me {
    groups {
      code
    }
  }
}
    `;

/**
 * __useCodesQuery__
 *
 * To run a query within a React component, call `useCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCodesQuery(baseOptions?: Apollo.QueryHookOptions<CodesQuery, CodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CodesQuery, CodesQueryVariables>(CodesDocument, options);
      }
export function useCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CodesQuery, CodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CodesQuery, CodesQueryVariables>(CodesDocument, options);
        }
export function useCodesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CodesQuery, CodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CodesQuery, CodesQueryVariables>(CodesDocument, options);
        }
export type CodesQueryHookResult = ReturnType<typeof useCodesQuery>;
export type CodesLazyQueryHookResult = ReturnType<typeof useCodesLazyQuery>;
export type CodesSuspenseQueryHookResult = ReturnType<typeof useCodesSuspenseQuery>;
export type CodesQueryResult = Apollo.QueryResult<CodesQuery, CodesQueryVariables>;