import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { DiamondIcon } from '../../assets/DiamondIcon.tsx';
import { LogoBlack } from '../../assets/LogoBlack.tsx';
import { LogoWhite5 } from '../../assets/LogoWhite5.tsx';
import { ExamsSummaryOrderBy, ExamsSummaryOrderDirection, GroupMemberRoleView } from '../../graphql/types.gen.ts';
import { useMeQuery } from '../../pages/FormPage/LoginPage/loginPage.gen.ts';
import { RootState } from '../../redux/store.ts';
import './Header.scss';
import { useScoreQuery } from './header.gen.ts';
export const Header = ({ formHeader = false, light = false }) => {
    // const { points } = useSelector((state: RootState) => state.bidding)
    const groupCode = useSelector((state: RootState) => state.user.chosenGroup);

    const { data: points, loading, error } = useScoreQuery();
    const { data: me } = useMeQuery({
        fetchPolicy: 'no-cache',
        variables: {
            input: {
                ordering: {
                    orderBy: ExamsSummaryOrderBy.Tag,
                    orderDirection: ExamsSummaryOrderDirection.Desc,
                },
            },
        },
    });
    const [wobbleClass, setWobbleClass] = useState('');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    useEffect(() => {
        setWobbleClass('wobble');
        setTimeout(() => {
            setWobbleClass('');
        }, 1000);
    }, [points]);
    //
    const pointsNumber = points?.me.groups.find((group) => group.code === groupCode)?.me.score;
    // const rankDetails = getRankDetailsByPoints(points)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsMobileMenuOpen((prev) => !prev);
    };
    const isInstructor = me?.me?.groups.find((group) => group.code === groupCode)?.me.role === GroupMemberRoleView.Instructor;
    const isLoggedIn = !!me?.me?.username;
    const USER_MENU = [
        { text: 'Nauka', link: '/nauka' },
        { text: 'Testy', link: '/game' },
        { text: 'Profil', link: '/profil' },
    ];
    const BACKOFFICE_MENU = [
        { text: 'Nauka', link: '/nauka' },
        { text: 'Testy', link: '/game' },
        { text: 'Edytuj testy', link: '/backoffice' },
        { text: 'Studenci', link: '/studenci' },
        { text: 'Profil', link: '/profil' },
    ];

    const CHOSEN_MENU = isInstructor ? BACKOFFICE_MENU : USER_MENU;

    return (
        <>
            <div className="header">
                <div className="header__inner">
                    <button
                        onClick={() => {
                            navigate('/');
                        }}
                        className="header__item"
                    >
                        <div className="header__logo">{light ? <LogoBlack /> : <LogoWhite5 />}</div>
                    </button>
                    <button className={`header__mobileTrigger ${isMobileMenuOpen && !formHeader ? 'header__mobileTrigger--opened' : ''}`} onClick={toggleMenu}>
                        <div className="header__hamburger"></div>
                        <div className="header__hamburger"></div>
                        <div className="header__hamburger"></div>
                    </button>
                    <ul className={`header__mobileMenu ${isMobileMenuOpen && !formHeader ? 'header__mobileMenu--opened' : ''}`}>
                        <li className="header__mobileMenu__item">
                            <NavLink className="header__mobileMenu__link" to="/">
                                Strona główna
                            </NavLink>
                        </li>
                        {CHOSEN_MENU.map((item) => (
                            <li key={item.text.toLocaleLowerCase() + 'mobile'} className="header__mobileMenu__item">
                                <NavLink className="header__mobileMenu__link" to={item.link}>
                                    {item.text}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    <div className={`header__items header__desktop ${formHeader ? ' header__items--hide' : ''}`}>
                        {CHOSEN_MENU.map((item) => (
                            <NavLink key={item.text.toLocaleLowerCase()} className="header__item" to={item.link}>
                                {item.text}
                            </NavLink>
                        ))}
                        {!!isLoggedIn ? (
                            <div className="header__user-wrapper">
                                <div className={`header__user-points-wrapper ${wobbleClass}`}>
                                    <p className="header__user-points">{pointsNumber}</p>
                                    <div className="header__user-diamond">
                                        <DiamondIcon />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <NavLink key="login" className="header__item" to="/login">
                                Zaloguj się
                            </NavLink>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
