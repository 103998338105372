import { CKEditor } from '@ckeditor/ckeditor5-react';
import { FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField } from '@mui/material';
import { ClassicEditor } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Button } from '../../components/Button/Button.tsx';
import { Footer } from '../../components/Footer/Footer.tsx';
import { Header } from '../../components/Header/Header.tsx';
import Hero from '../../components/Hero/Hero.tsx';
import { LessonCreateFailureReasonView, LessonDifficultyLevelView } from '../../graphql/types.gen.ts';
import { useValidateToken } from '../../helpers/useValidateToken.ts';
import { RootState } from '../../redux/store.ts';
import { EMPTY_TEST } from '../Backoffice/Backoffice.tsx';
import { useExamRemoveMutation, useGroupTagsQuery } from '../Backoffice/backoffice.gen.ts';
import { BackofficeTest } from '../Backoffice/components/BackofficeTest.tsx';
import './Lessons.scss';
import { editorConfig, slugify } from './helpers/Lessons.helpers.ts';
import { useCreateLessonMutation, useEditLessonMutation, useGetLessonBySlugLazyQuery, useGetLessonsHomeworkBySlugLazyQuery } from './lessons.gen.ts';

export const AddLesson = () => {
    const { titleId } = useParams();
    const backofficeTests = useRef<null | HTMLDivElement>(null);
    const user = useSelector((state: RootState) => state.user);
    const [editedTestId, setEditedTestId] = useState<string | null>(null);
    const [examRemoveMutation] = useExamRemoveMutation();
    const [editLessonMutation] = useEditLessonMutation();
    const [createLessonMutation] = useCreateLessonMutation();
    const { chosenGroup } = useSelector((state: RootState) => state.user);
    const [tag, setTag] = useState('');
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);
    // const [getExam, { data: testData, loading: testLoading, error: testError }] = useExamFromGroupLazyQuery();
    // const [offset, setOffset] = useState(0);
    const [emptyTest, setEmptyTest] = useState(false);
    const [stage2Id, setStage2Id] = useState(titleId || '');

    const [fetchLesson, { data: lesson }] = useGetLessonBySlugLazyQuery({
        fetchPolicy: 'no-cache',
        variables: {
            code: chosenGroup,
            lessonInput2: {
                slug: stage2Id,
            },
        },
    });
    const [fetchHomework, { data: lessonsHomework }] = useGetLessonsHomeworkBySlugLazyQuery({
        fetchPolicy: 'no-cache',
        variables: {
            code: chosenGroup,
            lessonInput2: {
                slug: titleId,
            },
        },
    });
    // const [page, setPage] = useState(1);

    // useEffect(() => {
    //     fetchData();
    // }, [offset]);

    // const total = data?.me.groups.filter((group) => group.code === user.chosenGroup)[0]?.exams.total;

    // const count = Math.ceil(total / LIMIT);
    const onSave = (id) => {
        toast.success('Test zaktualizowany pomyślnie');
        // tests?.items.length === 10 && emptyTest && handlePageChange(null, page + 1);
        setEmptyTest(false);
        // fetchData();
        handleEdit(id);
        console.log(id);
    };
    const onTestEdit = (id) => {
        toast.success('Test zaktualizowany pomyślnie');
        // tests?.items.length === 10 && emptyTest && handlePageChange(null, page + 1);
        setEmptyTest(false);
        fetchLesson();
        fetchHomework();
        console.log(id);
    };

    useEffect(() => {
        console.log(lesson);
        setTag(lesson?.group.lesson.tags[0]);
        setName(lesson?.group.lesson.title);
        setEditorContent(lesson?.group.lesson.content);
    }, [lesson?.group.lesson]);

    useEffect(() => {
        if (!stage2Id) return;
        fetchLesson();
        fetchHomework();
    }, []);

    // useEffect(() => {
    //     if (!testForLesson.length) return;
    //     handleEdit();
    // }, [testForLesson]);
    // const handlePageChange = (_e: any, value: number) => {
    //     setPage(value);
    //     setOffset((value - 1) * LIMIT);
    //     setEmptyTest(false);
    // };
    const handleRemoveClick = async (id: string) => {
        const response = await examRemoveMutation({
            variables: {
                input: {
                    examId: id,
                    groupCode: user.chosenGroup,
                },
            },
        });
        if (response.data?.examDelete?.reason === null) {
            toast.success('Test został usunięty');
            // fetchData();
            // tests?.items.length === 1 && handlePageChange(null, page - 1);
        } else {
            toast.error('Nie udało się usunąć testu');
        }
    };

    const addEmptyTest = () => {
        // handlePageChange(null, count);
        setEmptyTest(true);
        scrollToBottom();
    };
    useValidateToken();

    // useEffect(() => {
    //     if (!lesson?.group.lesson.homework) return;
    //     setTestForLesson(lesson?.group?.lesson?.homework.map((homework) => homework.id));
    // }, [lesson?.group?.lesson?.homework]);

    const scrollToBottom = () => {
        backofficeTests.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);
    const [editorContent, setEditorContent] = useState('');

    const handleEditorChange = (event, editor) => {
        const data = editor && editor.getData();
        setEditorContent(data);
        console.log(editorContent);
    };

    const { data, loading, error } = useGroupTagsQuery({
        variables: {
            code: chosenGroup,
        },
    });

    const handleChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        setTag(value);
    };
    const handleEdit = async (testId?: string) => {
        const currentTests = lessonsHomework.group.lesson.homework.map((homework) => homework.id);
        const response = await editLessonMutation({
            variables: {
                input: {
                    groupCode: chosenGroup,
                    revision: {
                        title: name,
                        tags: [tag],
                        content: editorContent,
                        description: '',
                        homeworkExamIds: testId ? [...currentTests, testId] : currentTests,
                        difficultyLevel: LessonDifficultyLevelView.Beginner,
                        slug: stage2Id,
                    },
                },
            },
        });
        if (response.data?.lessonRevisionCreate?.__typename === 'LessonRevisionCreateSuccessView') {
            setStage2Id(response.data?.lessonRevisionCreate.lessonSlug);
            fetchLesson();
            fetchHomework();
        } else {
            console.log('error in edit');
            handleError(response.data?.lessonRevisionCreate?.reason);
        }
    };
    console.log(stage2Id);
    const handleSubmit = async () => {
        console.log(`${chosenGroup}/lekcja/${slugify(name)}`);
        const response = await createLessonMutation({
            variables: {
                input: {
                    groupCode: chosenGroup,
                    insertBeforeLessonSlug: null,
                    revision: {
                        title: name,
                        tags: [tag],
                        content: editorContent,
                        description: '',
                        homeworkExamIds: [],
                        difficultyLevel: LessonDifficultyLevelView.Beginner,
                        slug: `${slugify(name)}`,
                    },
                },
            },
        });
        if (response.data?.lessonCreate?.__typename === 'LessonCreateSuccessView') {
            fetchLesson();
            fetchHomework();
            setStage2Id(response.data?.lessonCreate.lessonSlug);
            // navigate(`/${chosenGroup}/lesson/${slugify(name)}`);
        } else {
            handleError(response.data?.lessonCreate?.reason);
        }
    };

    const handleError = (error) => {
        switch (error) {
            case LessonCreateFailureReasonView.SlugAlreadyExists:
            case LessonCreateFailureReasonView.TitleAlreadyExists:
                toast.error('Lekcja o takim tytule już istnieje');
                break;
            case LessonCreateFailureReasonView.GroupNotFound:
                toast.error('Grupa do której chcesz dodac lekcję nie istnieje');
                break;
            case LessonCreateFailureReasonView.CreatorNotGroupInstructor:
                toast.error('Nie jesteś instruktorem tej grupy');
                break;
            case LessonCreateFailureReasonView.InvalidSlug:
                toast.error('Nieprawidłowy slug - tytuł lekcji');
                break;
            case LessonCreateFailureReasonView.LessonToInsertBeforeNotFound:
                toast.error('Nie znaleziono lekcji do której chcesz dodać nową');
                break;
            default:
                toast.error('Coś poszło nie tak');
                break;
        }
    };

    const isValid = name?.length > 0 && tag?.length > 0 && editorContent?.length > 0;
    return (
        <div className="lessons lesson__editLesson light">
            <ToastContainer />
            <div className="content">
                <Header light={true} />
                <Hero title="Dodaj lekcję" />
                <div className="lessons__container container">
                    <Paper className="lessons__paper lessons__paper--evenly">
                        <h2 className="lessons__paperTitle">Konfiguracja lekcji</h2>
                        <div className="row">
                            <FormControl style={{ minWidth: 120 }}>
                                <InputLabel id="tag-label">Tag</InputLabel>
                                <Select required labelId="tag-label" id="tag" value={tag} label="Tag" onChange={handleChange}>
                                    {data?.group?.possibleExamTags.map((tag) => {
                                        return (
                                            <MenuItem key={tag} value={tag}>
                                                {tag}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl style={{ minWidth: 120 }}>
                                <TextField
                                    id="outlined-controlled"
                                    label="Tytuł lekcji"
                                    value={name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setName(event.target.value);
                                    }}
                                    required
                                />
                            </FormControl>
                        </div>
                        <h2 className="lessons__paperTitle">Treść lekcji</h2>
                        <div className="main-container">
                            <div className="editor-container editor-container_classic-editor" ref={editorContainerRef}>
                                <div className="editor-container__editor">
                                    <div ref={editorRef}>{isLayoutReady && <CKEditor style={{ width: '100%' }} editor={ClassicEditor} data={editorContent} config={editorConfig} onChange={handleEditorChange} />}</div>
                                </div>
                            </div>
                        </div>
                        {!stage2Id ? (
                            <Button disabled={!isValid} text="Przejdź dalej" onClick={handleSubmit} />
                        ) : (
                            <div className="row justify--center">
                                <Button
                                    variant="ghost-gold"
                                    text="Wróć"
                                    onClick={() => {
                                        navigate(`/nauka`);
                                    }}
                                />
                                <Button
                                    variant="ghost-gold"
                                    text="Zapisz zmiany"
                                    onClick={() => {
                                        handleEdit();
                                    }}
                                />
                                <Button
                                    variant="ghost-gold"
                                    text="Zobacz lekcję"
                                    onClick={() => {
                                        navigate(`/${chosenGroup}/lesson/${slugify(stage2Id)}`);
                                    }}
                                />
                            </div>
                        )}
                    </Paper>
                </div>
                <div className={`lesson__homework ${stage2Id ? '' : 'lesson__homework--disabled'}`}>
                    <div className="lessons__container container">
                        <h2 className="lessons__paperTitle">Testy do zrobienia w lekcji</h2>
                    </div>
                    {!emptyTest && !loading && <Button customClass="backoffice__add" variant="ghost-gold" text={'Dodaj'} onClick={addEmptyTest} />}

                    {lessonsHomework &&
                        lessonsHomework.group.lesson.homework.map((homework, index) => {
                            return (
                                <BackofficeTest
                                    key={homework.id}
                                    index={index + 1}
                                    test={homework}
                                    onSave={onTestEdit}
                                    editMode={editedTestId === homework.id}
                                    handleLeaveEditClick={() => {
                                        setEditedTestId(null);
                                    }}
                                    handleEditClick={() => {
                                        setEditedTestId(homework.id);
                                    }}
                                    handleRemoveClick={handleRemoveClick}
                                />
                            );
                        })}
                    {/* {tests?.items.map((test) => (
                            <BackofficeTest
                                key={test.id}
                                index={tests?.items.indexOf(test) + 1 + offset}
                                test={test}
                                onSave={onSave}
                                editMode={editedTestId === test.id}
                                handleLeaveEditClick={() => {
                                    setEditedTestId(null);
                                }}
                                handleEditClick={() => {
                                    setEditedTestId(test.id);
                                }}
                                handleRemoveClick={handleRemoveClick}
                            />
                        ))} */}
                    {emptyTest && (
                        <BackofficeTest
                            index={lessonsHomework.group.lesson.homework.length + 1}
                            key="empty"
                            isForLesson={true}
                            test={{ ...EMPTY_TEST, tags: [tag], name: `test` }}
                            onSave={onSave}
                            editMode={true}
                            handleLeaveEditClick={() => {
                                setEmptyTest(false);
                                setEditedTestId(null);
                            }}
                            handleEditClick={() => {}}
                            handleRemoveClick={handleRemoveClick}
                        />
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};
