import React from 'react';
import { trumpNameToIcon } from '../../components/BiddingUI/BiddingUI.tsx';
import { BiddingCardView } from '../../graphql/types.gen.ts';
import { numberValueToNumber, suitsEnum } from '../../helpers.ts';
import './SmallCards.scss';

interface CardProps {
    card: BiddingCardView;
    index: number;
}

const suitsDic = {
    D: suitsEnum.DIAMONDS,
    S: suitsEnum.SPADES,
    C: suitsEnum.CLUBS,
    H: suitsEnum.HEARTS,
};

export const SmallCard = (props: CardProps) => {
    const { card, index } = props;
    //   console.log(card, index);
    if (!card) return;
    return (
        <div key={`card-index${card}`} className={`small-card${index === 0 ? ' first-small-card' : ''}`}>
            {/* <img style={{ transition: `0.4s opacity ${index}s` }} src={'./svg_playing_cards/back.svg'} className="small-cardImage front " /> */}
            <div className={`custom-small-card custom-small-card--${card.color}`}>
                <div className="custom-small-card__top">
                    <p className="custom-small-card__number custom-small-card__top__number">{numberValueToNumber(card.value)}</p>
                    <div className="custom-small-card__top__suit custom-small-card__suit">{trumpNameToIcon(card.color)}</div>
                </div>
                <div className="custom-small-card__middle">
                    <p className="custom-small-card__number custom-small-card__middle__number">{numberValueToNumber(card.value)}</p>
                </div>
                <div className="custom-small-card__bottom">
                    <p className="custom-small-card__number custom-small-card__bottom__number">{numberValueToNumber(card.value)}</p>
                    <div className="custom-small-card__bottom__suit custom-small-card__suit">{trumpNameToIcon(card.color)}</div>
                </div>
            </div>
        </div>
    );
};
